import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import weekday from "dayjs/plugin/weekday";
import {
  BillingTaxType,
  CalculatedShipmentStatus,
  ConfirmationStatus,
  CountGroupKey,
  DeliveryStatus,
  FulfillmentStatus,
  OrderItemStatus,
  OrderSortingKey,
  OrderSortingType,
  OrderStatus,
  OrderStatusFilter,
  OrderStatusMap,
  OrderTabKey,
  OrderTabKey_Pre1018,
  OrderTabsType,
  OrderTabsType_Pre1018,
  PaymentStatus,
  ShipmentStatus,
  ShipmentStatusMap,
} from "models";
import { t } from "utils/i18n";
import { BLUE, GRAY, GREEN, RED, YELLOW } from "./color";
import { TrackingContentName } from "./tracking";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(minMax);

export const ITEM_DISPLAY = 3;
export const SHIPMENT_DISPLAY = 3;

export const CAN_PACK_STATUSES = [
  ShipmentStatus.new,
  ShipmentStatus.waitManualConfirm,
  ShipmentStatus.providerTransferred,
  ShipmentStatus.waitProduct,
  ShipmentStatus.waitPicking,
];
export const CAN_DELIVERING_STATUSES = [
  ...CAN_PACK_STATUSES,
  ShipmentStatus.picking,
  ShipmentStatus.serviceCheck,
  ShipmentStatus.waitPacking,
  ShipmentStatus.packed,
  ShipmentStatus.wait3PlConfirm,
  ShipmentStatus.thirdPlPickedUp,
];
export const CAN_PRINT_SHIPMENT_STATUSES = [
  ShipmentStatus.delivered,
  ShipmentStatus.delivering,
  ShipmentStatus.thirdPlPickedUp,
  ShipmentStatus.cancelled,
  ShipmentStatus.partialCancelled,
];

export const ORDER_TABS_FILTER_PRE1018: OrderTabsType_Pre1018 = {
  [OrderTabKey_Pre1018.all]: {
    key: OrderTabKey_Pre1018.all,
    name: t("All"),
    defaultFilter: {
      status: [OrderStatus.processing],
      subStatus: [],
    },
  },
  [OrderTabKey_Pre1018.waitConfirm]: {
    key: OrderTabKey_Pre1018.waitConfirm,
    name: t("WaitingForConfirmation"),
    defaultFilter: {
      status: [OrderStatus.processing],
      subStatus: [OrderItemStatus.waitManualConfirm, OrderItemStatus.waitBackorderConfirm],
    },
    fieldDisabled: ["shipmentStatus", "status"],
  },
  [OrderTabKey_Pre1018.backorderInsufficientStock]: {
    key: OrderTabKey_Pre1018.backorderInsufficientStock,
    name: t("BackorderInsufficientStock"),
    defaultFilter: {
      status: [OrderStatus.processing],
      subStatus: [OrderItemStatus.backorderInsufficientStock],
    },
    fieldDisabled: ["shipmentStatus", "status"],
  },
  [OrderTabKey_Pre1018.waitProviders]: {
    key: OrderTabKey_Pre1018.waitProviders,
    name: t("WaitingForProviders"),
    defaultFilter: {
      shipmentStatus: [ShipmentStatus.providerTransferred],
    },
    fieldDisabled: ["shipmentStatus", "status"],
  },
  [OrderTabKey_Pre1018.backordering]: {
    key: OrderTabKey_Pre1018.backordering,
    name: t("Backordering"),
    defaultFilter: {
      status: [OrderStatus.processing],
      subStatus: [OrderItemStatus.backordering],
    },
    fieldDisabled: ["shipmentStatus", "status"],
  },
};

export const ORDER_TABS_FILTER: OrderTabsType = {
  [OrderTabKey.waitingForConfirmation]: {
    key: OrderTabKey.waitingForConfirmation,
    name: t("WaitingForConfirmation"),
    countGroupKey: CountGroupKey.waitingConfirmation,
    shipmentStatus: [ShipmentStatus.waitManualConfirm, ShipmentStatus.waitBackorderConfirm],
    trackingTab: "WaitingForConfirmationTab",
  },
  [OrderTabKey.backorderInsufficientStock]: {
    key: OrderTabKey.backorderInsufficientStock,
    name: t("InsufficientStock"),
    countGroupKey: CountGroupKey.insufficientStock,
    shipmentStatus: [ShipmentStatus.backorderInsufficientStock],
    trackingTab: "InsufficientStockTab",
  },
  [OrderTabKey.waitingForProduct]: {
    key: OrderTabKey.waitingForProduct,
    name: t("WaitingForProduct"),
    countGroupKey: CountGroupKey.waitingForProduct,
    shipmentStatus: [ShipmentStatus.backordering, ShipmentStatus.waitTransfer, ShipmentStatus.waitProduct],
    trackingTab: "WaitingForProductTab",
  },
  [OrderTabKey.waitingForDispatch]: {
    key: OrderTabKey.waitingForDispatch,
    name: t("WaitingForDispatch"),
    countGroupKey: CountGroupKey.waitingForDispatch,
    shipmentStatus: [
      ShipmentStatus.providerTransferred,
      ShipmentStatus.waitPicking,
      ShipmentStatus.picked,
      ShipmentStatus.waitPacking,
      ShipmentStatus.packed,
      ShipmentStatus.waitProduct,
    ],
    trackingTab: "WaitingForDispatchTab",
  },
  [OrderTabKey.search]: {
    key: OrderTabKey.search,
    name: t("Orders.Search"),
    trackingTab: "SearchOrdersTab",
  },
};

export const orderItemStatusInfo = [
  {
    status: OrderItemStatus.waitWarehouseConfirm,
    displayName: t("WaitWarehouseConfirmStatus"),
    displayColor: YELLOW["main"],
    priority: 0,
  },
  {
    status: OrderItemStatus.waitTransfer,
    displayName: t("WaitTransferStatus"),
    displayColor: YELLOW["main"],
    priority: 1,
  },
  {
    status: OrderItemStatus.waitBackorderConfirm,
    displayName: t("WaitBackorderConfirmStatus"),
    displayColor: YELLOW["main"],
    priority: 2,
  },
  {
    status: OrderItemStatus.waitManualConfirm,
    displayName: t("WaitManualConfirmStatus"),
    displayColor: YELLOW["main"],
    priority: 3,
  },
  {
    status: OrderItemStatus.backordering,
    displayName: t("BackorderingStatus"),
    displayColor: BLUE["light"],
    priority: 5,
  },
  {
    status: OrderItemStatus.backorderInsufficientStock,
    displayName: t("BackorderInsufficientStockStatus"),
    displayColor: RED["light"],
    priority: 4,
  },
  {
    status: OrderItemStatus.new,
    displayName: t("NewStatus"),
    displayColor: "",
    priority: 6,
  },
  {
    status: OrderItemStatus.cancelled,
    displayName: t("CancelledStatus"),
    displayColor: "",
    priority: 7,
  },
  {
    status: OrderItemStatus.returned,
    displayName: t("ReturnedStatus"),
    displayColor: "",
    priority: 8,
  },
];

export const ORDER_ITEM_STATUS_FILTER_OPTIONS = [
  {
    status: OrderItemStatus.waitTransfer,
    name: t("WaitTransferStatus"),
    isActive: true,
  },
  {
    status: OrderItemStatus.waitBackorderConfirm,
    name: t("WaitBackorderConfirmStatus"),
    isActive: true,
  },
  {
    status: OrderItemStatus.backordering,
    name: t("BackorderingStatus"),
    isActive: true,
  },
  {
    status: OrderItemStatus.backorderInsufficientStock,
    name: t("BackorderInsufficientStockStatus"),
    isActive: true,
  },
];

export const DELIVERY_STATUS_MAP: { [key: string]: { name: string; color: string } } = {
  [DeliveryStatus.created]: {
    name: t("RequestCreated"),
    color: GRAY["light"],
  },
  [DeliveryStatus.sentToProvider]: {
    name: t("SentToProvider"),
    color: BLUE["light"],
  },
  [DeliveryStatus.cancelled]: {
    name: t("Cancelled"),
    color: GRAY["main"],
  },
  [DeliveryStatus.returned]: {
    name: t("Returned"),
    color: GRAY["main"],
  },
  [DeliveryStatus.delivered]: {
    name: t("Orders.ShipmentStatus.Delivered"),
    color: GREEN["light"],
  },
  [DeliveryStatus.delivering]: {
    name: t("Orders.ShipmentStatus.Delivering"),
    color: BLUE["light"],
  },
  [DeliveryStatus._3PLPicked]: {
    name: t("3PLPicked"),
    color: BLUE["light"],
  },
  [DeliveryStatus.deliveryRequested]: {
    name: t("DeliveryRequested"),
    color: BLUE["light"],
  },
  [DeliveryStatus.cancelRejected]: {
    name: t("CancelRejected"),
    color: GRAY["main"],
  },
};

export const ORDER_SORTING_FILTER: OrderSortingType = {
  [OrderSortingKey.createdAtAsc]: OrderSortingKey.createdAtAsc,
  [OrderSortingKey.createdAtDesc]: OrderSortingKey.createdAtDesc,
  [OrderSortingKey.updatedAtAsc]: OrderSortingKey.updatedAtAsc,
  [OrderSortingKey.updatedAtDesc]: OrderSortingKey.updatedAtDesc,
};

export const PICKUP_AT_SHOWROOM = 4;

export const MIN_ITEM_QUANTITY_FOR_CREATE = 0;
export const MIN_ITEM_QUANTITY_FOR_EDIT = 0.0001;
export const MAX_ITEM_QUANTITY_DECIMAL_PLACES = 4;

export const EXPORT_DATE_RANGE_LIMIT = 31;
export const DEFAULT_SEARCH_DATE_RANGE_LIMIT = 30;

export const NOT_ALLOWED_TO_CANCEL_BOL_STATUSES = [
  ShipmentStatus.delivered,
  ShipmentStatus.waitReturnConfirm,
  ShipmentStatus.waitRevertConfirm,
  ShipmentStatus.reverted,
  ShipmentStatus.returned,
];

export enum Referrer {
  CustomerQuotation = "CUSTOMER_QUOTATION", // hardcode for product matching screen
}

export const COMMENT_MAX_LENGTH = 5000;
export const COMMENT_BOX_ROWS = 3;

export const GET_CUSTOMER_LIST_PARAMS = {
  SORT_FIELD: {
    UPDATED_AT: "updated_at",
  },
  SORT_ORDER: {
    DESCENDING: "desc",
  },
};

export const MIN_LENGTH_TO_SEARCH_BY_PHONE = 6;

export const DELIVERING_TO_CUSTOMER_STATUSES = [
  ShipmentStatus.thirdPlPickedUp,
  ShipmentStatus.delivering,
  ShipmentStatus.wait3PlConfirm, // OMv2 status
  ShipmentStatus.thirdPlConfirmed, // OMv2 status
];

export const orderStatusMap: OrderStatusMap[] = [
  {
    label: t("OrderStatusLabel.Pending"),
    value: OrderStatus.pending,
    color: BLUE.light,
    status: {
      confirmationStatus: ConfirmationStatus.pending,
    },
  },
  {
    label: t("OrderStatusLabel.CancelledBeforeFulfill"),
    value: OrderStatus.cancelledBeforeFulfill,
    color: RED.light,
    status: {
      confirmationStatus: ConfirmationStatus.cancelled,
    },
  },
  {
    label: t("OrderStatusLabel.Unpaid"),
    value: OrderStatus.unpaid,
    color: GRAY.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.pending,
    },
  },
  {
    label: t("OrderStatusLabel.PartiallyPaid"),
    value: OrderStatus.partiallyPaid,
    color: YELLOW.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.partiallyPaid,
    },
  },
  {
    label: t("OrderStatusLabel.Delivered"),
    value: OrderStatus.delivered,
    color: GREEN.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: true,
    },
    isRemovedInOMNI1058: true,
  },
  {
    label: t("OrderStatusLabel.Delivered"),
    value: OrderStatus.delivered,
    color: GREEN.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: true,
      hasReturnedFullItems: false,
    },
    isAddedInOMNI1058: true,
  },
  {
    label: t("OrderStatusLabel.Delivered"),
    value: OrderStatus.delivered,
    color: GREEN.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.delivered,
    },
  },
  {
    label: t("OrderStatusLabel.Processing"),
    value: OrderStatus.processing,
    color: BLUE.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.new,
    },
    isRemovedInOMNI1058: true,
  },
  {
    label: t("OrderStatusLabel.Processing"),
    value: OrderStatus.processing,
    color: BLUE.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.processing,
    },
  },
  {
    label: t("OrderStatusLabel.NeedAction"),
    value: OrderStatus.needAction,
    color: YELLOW.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.needAction,
    },
  },
  {
    label: t("CancelledStatus"),
    value: OrderStatus.cancelled,
    color: GRAY.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.cancelled,
    },
  },
  {
    label: t("OrderStatusLabel.Returned"),
    value: OrderStatus.returned,
    color: GRAY.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
      fulfillmentStatus: FulfillmentStatus.returned,
    },
  },
  {
    label: t("OrderStatusLabel.Returned"),
    value: OrderStatus.returned,
    color: GRAY.main,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: true,
      hasReturnedFullItems: true,
    },
    isAddedInOMNI1058: true,
  },
  {
    label: t("OrderStatusLabel.OrderPlaced"),
    value: OrderStatus.orderPlaced,
    color: BLUE.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
      paymentStatus: PaymentStatus.fullyPaid,
      isHandover: false,
    },
    isAddedInOMNI1058: true,
  },
  {
    label: t("OrderStatusLabel.Approved"),
    value: OrderStatus.confirmed,
    color: BLUE.light,
    status: {
      confirmationStatus: ConfirmationStatus.active,
    },
    isAddedInOMNI1058: true,
  },
];

export const ORDER_STATUS_FILTER: OrderStatusFilter = {
  [OrderStatus.pending]: {
    name: t("OrderStatusLabel.Pending"),
    params: {
      confirmationStatus: [ConfirmationStatus.pending],
    },
  },
  [OrderStatus.cancelledBeforeFulfill]: {
    name: t("OrderStatusLabel.CancelledBeforeFulfill"),
    params: {
      confirmationStatus: [ConfirmationStatus.cancelled],
    },
  },
  [OrderStatus.confirmed]: {
    name: t("OrderStatusLabel.Confirmed"),
    params: {
      confirmationStatus: [ConfirmationStatus.active],
    },
  },
  [OrderStatus.unpaid]: {
    name: t("OrderStatusLabel.Unpaid"),
    params: {
      confirmationStatus: [ConfirmationStatus.active],
      paymentStatus: [PaymentStatus.pending],
    },
  },
  [OrderStatus.partiallyPaid]: {
    name: t("OrderStatusLabel.PartiallyPaid"),
    params: {
      confirmationStatus: [ConfirmationStatus.active],
      paymentStatus: [PaymentStatus.partiallyPaid],
    },
  },
  [OrderStatus.delivered]: {
    name: t("OrderStatusLabel.Delivered"),
    params: {
      isDelivered: true,
      hasReturnedFullItems: false,
    },
  },
  [OrderStatus.needAction]: {
    name: t("OrderStatusLabel.NeedAction"),
    params: {
      fulfillmentStatus: [FulfillmentStatus.needAction],
    },
  },
  [OrderStatus.processing]: {
    name: t("OrderStatusLabel.Processing"),
    params: {
      fulfillmentStatus: [FulfillmentStatus.new, FulfillmentStatus.processing],
    },
  },
  [OrderStatus.cancelled]: {
    name: t("OrderStatusLabel.Cancelled"),
    params: {
      fulfillmentStatus: [FulfillmentStatus.cancelled],
    },
  },
  [OrderStatus.returned]: {
    name: t("OrderStatusLabel.Returned"),
    params: {
      hasReturnedFullItems: true,
    },
  },
};

export const SHIPMENT_STATUS_MAP: ShipmentStatusMap = {
  [ShipmentStatus.waitTransfer]: {
    name: t("ShipmentStatusLabel.WaitTransfer"),
    shipmentStatus: [ShipmentStatus.waitTransfer],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.waitManualConfirm]: {
    name: t("ShipmentStatusLabel.WaitManualConfirm"),
    shipmentStatus: [ShipmentStatus.waitManualConfirm],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.waitBackorderConfirm]: {
    name: t("ShipmentStatusLabel.WaitBackorderConfirm"),
    shipmentStatus: [ShipmentStatus.waitBackorderConfirm],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.backordering]: {
    name: t("ShipmentStatusLabel.Backordering"),
    shipmentStatus: [ShipmentStatus.backordering],
    useForFilter: true,
    color: BLUE.light,
  },
  [ShipmentStatus.backorderInsufficientStock]: {
    name: t("ShipmentStatusLabel.BackorderInsufficientStock"),
    shipmentStatus: [ShipmentStatus.backorderInsufficientStock],
    useForFilter: true,
    color: RED.light,
  },
  [ShipmentStatus.providerTransferred]: {
    name: t("ShipmentStatusLabel.ProviderTransferred"),
    shipmentStatus: [ShipmentStatus.providerTransferred],
    useForFilter: true,
    color: BLUE.light,
  },
  [ShipmentStatus.waitProduct]: {
    name: t("ShipmentStatusLabel.WaitProduct"),
    shipmentStatus: [ShipmentStatus.waitProduct],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.waitPicking]: {
    name: t("ShipmentStatusLabel.WaitPicking"),
    shipmentStatus: [ShipmentStatus.waitPicking],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.picking]: {
    name: t("ShipmentStatusLabel.Picking"),
    shipmentStatus: [ShipmentStatus.picking],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.picked]: {
    name: t("ShipmentStatusLabel.Picked"),
    shipmentStatus: [ShipmentStatus.picked],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.waitPacking]: {
    name: t("ShipmentStatusLabel.WaitPacking"),
    shipmentStatus: [ShipmentStatus.waitPacking],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.packed]: {
    name: t("ShipmentStatusLabel.Packed"),
    shipmentStatus: [ShipmentStatus.packed],
    useForFilter: true,
    color: YELLOW.main,
  },
  [ShipmentStatus.serviceCheck]: {
    name: t("ShipmentStatusLabel.ServiceCheck"),
    shipmentStatus: [ShipmentStatus.serviceCheck],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.readyPickup]: {
    name: t("ShipmentStatusLabel.ReadyPickup"),
    shipmentStatus: [ShipmentStatus.readyPickup],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.thirdPlPickedUp]: {
    name: t("ShipmentStatusLabel.ThirdPlPickedUp"),
    shipmentStatus: [ShipmentStatus.thirdPlPickedUp],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.wait3PlConfirm]: {
    name: t("ShipmentStatusLabel.Wait3plConfirmation"),
    shipmentStatus: [ShipmentStatus.wait3PlConfirm],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.delivering]: {
    name: t("ShipmentStatusLabel.Delivering"),
    shipmentStatus: [ShipmentStatus.delivering],
    color: BLUE.light,
    useForFilter: true,
  },
  [CalculatedShipmentStatus.deliveringToAgency]: {
    name: t("ShipmentStatusLabel.DeliveringToAgency"),
    shipmentStatus: [],
    color: BLUE.light,
    useForFilter: false,
  },
  [ShipmentStatus.delivered]: {
    name: t("ShipmentStatusLabel.Delivered"),
    shipmentStatus: [ShipmentStatus.delivered],
    color: GREEN.main,
    useForFilter: true,
  },
  [ShipmentStatus.deliveredAtAgency]: {
    name: t("ShipmentStatusLabel.DeliveredAtAgency"),
    shipmentStatus: [ShipmentStatus.deliveredAtAgency],
    color: BLUE.light,
    useForFilter: true,
  },
  [ShipmentStatus.partialCancelled]: {
    name: t("ShipmentStatusLabel.PartialCancelled"),
    shipmentStatus: [ShipmentStatus.partialCancelled],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.cancelled]: {
    name: t("ShipmentStatusLabel.Cancelled"),
    shipmentStatus: [ShipmentStatus.cancelled],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.returned]: {
    name: t("ShipmentStatusLabel.Returned"),
    shipmentStatus: [ShipmentStatus.returned],
    color: GRAY.main,
    useForFilter: true,
  },
  [ShipmentStatus.new]: {
    name: t("ShipmentStatusLabel.New"),
    shipmentStatus: [],
    color: GRAY.main,
    useForFilter: false,
  },
  [ShipmentStatus.providerTransferring]: {
    name: t("ShipmentStatusLabel.ProviderTransferring"),
    shipmentStatus: [],
    color: GRAY.main,
    useForFilter: false,
  },
  [ShipmentStatus.waitRevertConfirm]: {
    name: t("ShipmentStatusLabel.WaitRevertConfirm"),
    shipmentStatus: [],
    color: GRAY.main,
    useForFilter: false,
  },
  [ShipmentStatus.waitReturnConfirm]: {
    name: t("ShipmentStatusLabel.WaitReturnConfirm"),
    shipmentStatus: [],
    color: GRAY.main,
    useForFilter: false,
  },
  [ShipmentStatus.reverted]: {
    name: t("ShipmentStatusLabel.Reverted"),
    shipmentStatus: [],
    color: GRAY.main,
    useForFilter: false,
  },
  OTHER: {
    name: t("Other"),
    shipmentStatus: [
      ShipmentStatus.new,
      ShipmentStatus.providerTransferring,
      ShipmentStatus.waitRevertConfirm,
      ShipmentStatus.waitReturnConfirm,
      ShipmentStatus.reverted,
    ],
    color: undefined,
    useForFilter: true,
  },
};

export const HANDOVER_ORDER_STATUS_MAP: ShipmentStatusMap = {
  // Status for handover order: https://jira.teko.vn/browse/OMPRODUCT-21
  [OrderStatus.pending]: {
    name: t("OrderStatusLabel.Pending"),
    shipmentStatus: [OrderStatus.pending],
    color: BLUE.light,
    useForFilter: true,
  },
  [OrderStatus.cancelledBeforeFulfill]: {
    name: t("OrderStatusLabel.CancelledBeforeFulfill"),
    shipmentStatus: [OrderStatus.cancelledBeforeFulfill],
    color: RED.light,
    useForFilter: true,
  },
  [OrderStatus.unpaid]: {
    name: t("OrderStatusLabel.Unpaid"),
    shipmentStatus: [OrderStatus.unpaid],
    color: GRAY.main,
    useForFilter: true,
  },
  [OrderStatus.partiallyPaid]: {
    name: t("OrderStatusLabel.PartiallyPaid"),
    shipmentStatus: [OrderStatus.partiallyPaid],
    color: YELLOW.light,
    useForFilter: true,
  },
};

export const ORDER_LIST_DEFAULT_LIMIT = 50;
export const ORDER_LIST_WITH_SHIPMENT_INFO_LIMIT = 10;

export const FETCH_SHIPMENT_INFO_DELAY_TIME_MILLISECONDS = 200;

export const ORDER_FILTER_DATE_RANGE_OPTIONS = [7, 30];

// Set default date range for both getCountNeedToActionOrders and getMarketOrders to ensure matching number of orders
export const ORDER_LIST_DEFAULT_DATE_RANGE = [dayjs().subtract(3, "month").startOf("day"), dayjs().endOf("day")];

export const SELLER_ORDER_LIST_DEFAULT_SHIPMENT_STATUSES = [
  ShipmentStatus.waitTransfer,
  ShipmentStatus.waitManualConfirm,
  ShipmentStatus.waitBackorderConfirm,
  ShipmentStatus.backordering,
  ShipmentStatus.backorderInsufficientStock,
  ShipmentStatus.waitProduct,
];

export const UNKNOWN_PRICE_VALUE = -1;

export const MIN_LENGTH_TO_SEARCH_BY_SKU = 3;
export const SKU_COUNT_LIMIT = 10;
export const FILTER_SKU_DATE_RANGE_LIMIT = 31;

export const MAX_WAIT_UPDATE_SHIPMENT_STATUS_IN_SECONDS = 10;

export const BILLING_TAX_TYPE_OPTIONS = [
  {
    value: BillingTaxType.VAT,
    label: t("BillingTaxType.VAT"),
  },
  {
    value: BillingTaxType.TAX_REFUND_INVOICE,
    label: t("BillingTaxType.TaxRefund"),
  },
  {
    value: BillingTaxType.TAX_FREE_INVOICE,
    label: t("BillingTaxType.TaxFree"),
  },
  {
    value: BillingTaxType.TAX_GIFT_INVOICE,
    label: t("BillingTaxType.GiftItems"),
  },
];

export const BILLING_TAX_TYPE_MAP: {
  [key in BillingTaxType]: { label: string };
} = {
  [BillingTaxType.VAT]: {
    label: t("BillingTaxType.VAT"),
  },
  [BillingTaxType.TAX_REFUND_INVOICE]: {
    label: t("BillingTaxType.TaxRefund"),
  },
  [BillingTaxType.TAX_FREE_INVOICE]: {
    label: t("BillingTaxType.TaxFree"),
  },
  [BillingTaxType.TAX_GIFT_INVOICE]: {
    label: t("BillingTaxType.GiftItems"),
  },
};

export const DELAY_TIME_BEFORE_FETCHING_ORDER_DETAIL = 3000;

export const SELLER_ORDER_FILTER_FORM_TRACKING_MAP = {
  orderId: TrackingContentName.OrderIdInput,
  phone: TrackingContentName.PhoneNumberInput,
  consultantId: TrackingContentName.ConsultantIdInput,
  shipmentStatus: TrackingContentName.StatusSelect,
  platformId: TrackingContentName.PlatformSelect,
  terminalId: TrackingContentName.TerminalSelect,
  orderCreatedDate: TrackingContentName.OrderCreatedDateRangeSelect,
  deliveredDate: TrackingContentName.DeliveredDateRangeSelect,
  paymentDate: TrackingContentName.PaymentDateRangeSelect,
  skus: TrackingContentName.ProductSelect,
};

export const QUICK_TAB_PLATFORM_ORDER_FILTER_FORM_TRACKING_MAP = {
  sellerId: TrackingContentName.SellerSelect,
  terminalId: TrackingContentName.TerminalSelect,
  consultantId: TrackingContentName.ConsultantIdInput,
  exportSiteIds: TrackingContentName.SiteSelect,
  skus: TrackingContentName.ProductSelect,
};

export const SEARCH_TAB_PLATFORM_ORDER_FILTER_FORM_TRACKING_MAP = {
  sellerId: TrackingContentName.SellerSelect,
  terminalId: TrackingContentName.TerminalSelect,
  phone: TrackingContentName.PhoneNumberInput,
  consultantId: TrackingContentName.ConsultantIdInput,
  orderStatus: TrackingContentName.OrderStatusSelect,
  shipmentStatus: TrackingContentName.ShipmentStatusSelect,
  orderCreatedDate: TrackingContentName.OrderCreatedDateRangeSelect,
  deliveredDate: TrackingContentName.DeliveredDateRangeSelect,
  paymentDate: TrackingContentName.PaymentDateRangeSelect,
  exportSiteIds: TrackingContentName.SiteSelect,
  skus: TrackingContentName.ProductSelect,
};
