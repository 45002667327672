import { ClearanceRequestStatus, IShippingTypeLocation, ReturnRequestStatus } from "models";
import { t } from "utils/i18n";
import fileConstants from "./file";
import stockImbalanceConstants from "./stock-imbalance";

const IAM_SCOPES = [
  "openid",
  "profile",
  "read:permissions",
  // You can add more scopes (granted services) here
  "accreport",
  "apl",
  "as",
  "dca",
  "sellers",
  "staff-bff",
  "ticket-bff",
  "wms",
  "catalog",
  "tenant:management",
  "us",
  "ons-bff",
  "uns-bff-api",
];

const PAGE_SIZE_OPTIONS = ["10", "20", "50"];
const MAX_PAGE_SIZE = 200;
const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
};

const DEFAULT_SIZE_CHANGER = {
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  locale: {
    items_per_page: t("RecordPerPage"),
  },
};

const DEFAULT_FILTERS = {
  MANUAL_INVENTORY: {
    TRACKING: {
      page: DEFAULT_PAGINATION.current,
      pageSize: DEFAULT_PAGINATION.pageSize,
      keyword: undefined,
    },
    FILE_UPLOAD_INVENTORY_HISTORY: {
      offset: 0,
      limit: DEFAULT_PAGINATION.pageSize,
      type: fileConstants.FILE_TYPE.INVENTORY,
    },
    FILE_UPLOAD_WAREHOUSE_HISTORY: {
      type: fileConstants.FILE_TYPE.WAREHOUSE,
    },
    FILE_UPLOAD_SITE_HISTORY: {
      type: fileConstants.FILE_TYPE.SITE,
    },
    LIST_WAREHOUSE: {
      page: DEFAULT_PAGINATION.current,
      pageSize: DEFAULT_PAGINATION.pageSize,
    },
    LIST_SITE: {
      page: DEFAULT_PAGINATION.current,
      pageSize: DEFAULT_PAGINATION.pageSize,
    },
  },
  PLATFORM: {
    page: DEFAULT_PAGINATION.current,
    pageSize: MAX_PAGE_SIZE,
  },
  TERMINAL: {
    page: DEFAULT_PAGINATION.current,
    pageSize: MAX_PAGE_SIZE,
  },
  RETURN_REQUEST: {
    page: DEFAULT_PAGINATION.current,
    pageSize: DEFAULT_PAGINATION.pageSize,
    statuses: [
      ReturnRequestStatus.waitApproval,
      ReturnRequestStatus.waitApprovalImported,
      ReturnRequestStatus.waitApprovalCancelled,
    ],
    orderCodes: undefined,
    sellerId: undefined,
    requestDate: undefined,
    createdByEmail: undefined,
  },
  RETURN_REASON: {
    isStaffOnly: false,
    status: undefined,
    page: DEFAULT_PAGINATION.current,
    pageSize: DEFAULT_PAGINATION.pageSize,
  },
  INVENTORY_ALERT: {
    page: DEFAULT_PAGINATION.current,
    pageSize: DEFAULT_PAGINATION.pageSize,
    status: `${ClearanceRequestStatus.available},${ClearanceRequestStatus.expired}`,
    originalSku: undefined,
  },
  PURCHASE_ORDERS: {
    limit: 10,
    offset: 0,
  },
  QUOTATION_LIST: {
    page: DEFAULT_PAGINATION.current,
    pageSize: DEFAULT_PAGINATION.pageSize,
  },
  STOCK_IMBALANCE: {
    offset: 0,
    limit: stockImbalanceConstants.SKU_LIMIT,
  },
  BRAND: {
    page: DEFAULT_PAGINATION.current,
    pageSize: DEFAULT_PAGINATION.pageSize,
  },
};

const BYPASSED_NOT_FOUND_ERROR_URLS = [/delivery\/\d+\/cancel-request$/, /loyalty-computation-config/];

const STATUS_CODES = {
  IAM_CONFIG_NOT_FOUND: 40411,
};

const NOT_AVAILABLE = "N/A";
const NOT_FOUND = "Not Found";

export const TAKASHIMAYA_DOMAINS = [
  "https://admin.takashimaya-vn.com", //PROD
  "https://tkv-staff.stag.teko.vn", //STAG
  "https://tkv-staff.dev.teko.vn", //DEV
];

export const VIEON_DOMAINS = [
  "https://agency-admin.vieon.vn", //PROD
  "https://admin-vieon.stag.teko.vn", //STAG
  "https://admin-vieon.dev.teko.vn", //DEV
];

const X_SELLER_ID = "x-seller-id";
const X_PLATFORM_ID = "x-platform-id";

const VND_CURRENCY = {
  symbol: "₫",
  precision: 3,
};

const MIDDLE_DOT_SYMBOL = "·";

const DISCOUNT_SKU_FOR_PHONGVU = "1600103";
const STATUS_CODES_ACTIVE_BFF_CATALOG = "active,processing,pending_approval,reject";

const PURCHASE_INVOICE_UNIT_PRICE_MAX_DIFF = 500;

const DURATION_IN_SECONDS = {
  ONE_SECOND: 1,
};

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/;
const PHONE_REGEX = /^((\+84\s?|\+84\s?0|0)[3|5|7|8|9])+([0-9]{8})$/;
const TELEPHONE_REGEX = /^02\d{8,9}$/;
const TAX_CODE_REGEX = /^\d{10}(-\d{3})?$/; // Tax code format: 10 digits (1234567890) or 13 digits (1234567890-000)

const START_EDIT_MESSAGE = "start edit";
const END_EDIT_MESSAGE = "stop edit";

const START_DOWNLOAD_MESSAGE = "start download";
const END_DOWNLOAD_MESSAGE = "end download";

const LOGOUT_MESSAGE = "logout";

const MAX_TEXT_INPUT_LENGTH = 255;

const DEFAULT_LOCATIONS_VALUE: IShippingTypeLocation[] = [
  {
    locationId: "0",
    locationName: "Toàn quốc",
  },
];

const ENTERPRISE_WAREHOUSE_PROVIDER = "-1";

enum PROMISE_STATUS {
  Fulfilled = "fulfilled",
  Rejected = "rejected",
}

const SERVICE_QUANTITY = 1;
const ZERO_QUANTITY = 0;

const DELAY_TIME_BEFORE_DIRECT_TO_EXPORT_HISTORY = 500;
const DELAY_TIME = 500;
const DELAY_TIME_BEFORE_DIRECT_TO_LIST_POR = 2000;

const DEFAULT_NUMBER_DISPLAY_FORMAT = {
  thousandSeparator: ".",
  decimalSeparator: ",",
};
const OM_DEFAULT_NUMBER_DISPLAY_FORMAT = {
  thousandSeparator: ",",
  decimalSeparator: ".",
};
const DEFAULT_DECIMAL_SEPARATOR = ".";

const THOUSAND_FORMAT_REGEX = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
const INTEGER_OR_FLOAT_REGEX = new RegExp(/^-?\d+(\.\d+)?$/);

export default {
  ALL: "all",
  APP_TITLE: "HCM2 ERP Staff",
  BYPASSED_NOT_FOUND_ERROR_URLS,
  DEBOUNCE_WAITING_TIME: 500, // in milliseconds
  DEFAULT_FILTERS,
  DEFAULT_LOCATIONS_VALUE,
  DEFAULT_PAGINATION,
  DEFAULT_SIZE_CHANGER,
  DISCOUNT_SKU_FOR_PHONGVU, // hard code for Phong Vũ to discount in Purchase Invoice
  STATUS_CODES_ACTIVE_BFF_CATALOG,
  POLLING_INTERVAL: 1000, // milliseconds
  DOWNLOAD_POLLING_INTERVAL: 10000, // in milliseconds
  DURATION_IN_SECONDS,
  EMAIL_REGEX,
  END_DOWNLOAD_MESSAGE,
  END_EDIT_MESSAGE,
  ENTERPRISE_WAREHOUSE_PROVIDER,
  IAM_SCOPES,
  LOGOUT_MESSAGE,
  MAX_PAGE_SIZE,
  MAX_TEXT_INPUT_LENGTH,
  MIDDLE_DOT_SYMBOL,
  NOT_AVAILABLE,
  NOT_FOUND,
  PAGE_SIZE_OPTIONS,
  PHONE_REGEX,
  TELEPHONE_REGEX,
  PROMISE_STATUS,
  PURCHASE_INVOICE_UNIT_PRICE_MAX_DIFF,
  SERVICE_QUANTITY,
  ZERO_QUANTITY,
  START_DOWNLOAD_MESSAGE,
  START_EDIT_MESSAGE,
  STATUS_CODES,
  TABLET_WIDTH: 768,
  TAKASHIMAYA_DOMAINS, // hardcode for Takashimaya E-Invoice,
  TAX_CODE_REGEX,
  VND_CURRENCY,
  X_SELLER_ID,
  X_PLATFORM_ID,
  DELAY_TIME_BEFORE_DIRECT_TO_EXPORT_HISTORY,
  DELAY_TIME,
  ONE_MIN_IN_MS: 60000,
  DELAY_TIME_BEFORE_DIRECT_TO_LIST_POR,
  DEFAULT_NUMBER_DISPLAY_FORMAT,
  OM_DEFAULT_NUMBER_DISPLAY_FORMAT,
  DEFAULT_DECIMAL_SEPARATOR,
  THOUSAND_FORMAT_REGEX,
  DEFAULT_PRECISION: 4,
  INTEGER_OR_FLOAT_REGEX,
};
