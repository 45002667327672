import { Spin } from "antd";
import { appConfig } from "app-config";
import "App.less"; // must import these base stylesheets BEFORE the components, to prevent components' styles from being overwritten by base styles
import "App.scss";
import AppLayout from "components/AppLayout";
import PrivateRoute from "components/PrivateRoute";
import Route from "components/Route";
import { commonConstants } from "constants/index";
import Page403 from "pages/Page403";
import Page404 from "pages/Page404";
import Page500 from "pages/Page500";
import React, { useEffect, useState } from "react";
import { Router, Switch } from "react-router-dom";
import ReactTracker from "react-tracker-teko";
import routes from "routes";
import { browserHistory } from "utils";
import { t } from "utils/i18n";
import iframeUtils from "utils/iframe";
import StaffIdentity from "@staff-admin/identity-sdk";

const { IAM_SCOPES } = commonConstants;
const { trackingAppId } = iframeUtils.getDataFromIframe();

const { tracker, isUseStaffAdminIDSDK } = appConfig;
const reactTracker = new ReactTracker({
  host: ((trackingAppId || tracker.appId) && tracker.host) || "",
  urlServeJsFile: ((trackingAppId || tracker.appId) && tracker.jsFile) || "",
  appId: trackingAppId || tracker.appId || "",
});

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { clientId: iframeClientId, oauthDomain: iframeOauthDomain } = iframeUtils.getDataFromIframe();
    const clientId = iframeClientId || appConfig.iam.clientId;
    const oauthDomain = iframeOauthDomain || appConfig.iam.oauthDomain;

    const staffIdentity = StaffIdentity(isUseStaffAdminIDSDK);
    staffIdentity
      .init({
        redirectUri: window.location.origin,
        scopes: IAM_SCOPES,
        clientId,
        oauthDomain,
        checkTokenRevoked: true,
      })
      .then(() => {
        // The initial phase is finish.
        // Now you can do your logic.
        setLoading(false);
      });

    return () => {
      staffIdentity.removeEventListener();
    };
  }, []);

  return loading ? (
    <Spin className="app-spin" />
  ) : (
    <Router
      history={reactTracker.connectToHistory(
        browserHistory,
        routes.filter((route) => !!route.component && route.exact === true)
      )}
    >
      <Switch>
        <Route exact path="/403" name="403" component={Page403} />
        <Route exact path="/404" name="404" component={Page404} />
        <Route exact path="/500" name="500" component={Page500} />
        <PrivateRoute path="/" name={t("Home")} component={AppLayout} />
      </Switch>
    </Router>
  );
};

export default App;
