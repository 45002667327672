import {
  CAN_DELIVERING_STATUSES,
  CAN_PACK_STATUSES,
  HANDOVER_ORDER_STATUS_MAP,
  MAX_ITEM_QUANTITY_DECIMAL_PLACES,
  ORDER_STATUS_FILTER,
  SHIPMENT_STATUS_MAP,
  UNKNOWN_PRICE_VALUE,
  actionConstants,
  appConstants,
  commonConstants,
  orderStatusMap,
  resourceConstants,
  roleConstants,
} from "constants/index";
import { CAN_PRINT_SHIPMENT_STATUSES } from "constants/orders";
import dayjs from "dayjs";
import _, { isEqual } from "lodash";
import lodashGet from "lodash/get";
import lodashGroupBy from "lodash/groupBy";
import {
  ActionCode,
  CalculatedShipmentStatus,
  ConfirmationStatus,
  Consultant,
  FulfillmentStatus,
  GeneralViewItem,
  IConsultant,
  IOrderItem,
  IShipment,
  ItemType,
  MarketOrder,
  OrderActionCode,
  OrderCaptureLineItem,
  OrderDetails,
  OrderDisplayStatus,
  OrderItemSource,
  OrderItemStatus,
  OrderStatus,
  OrderStatusFilter,
  OrderTableRecord,
  PaymentStatus,
  PriceBiasedItem,
  RawOrderDetails,
  RawShipment,
  Service,
  Shipment,
  ShipmentActionCode,
  ShipmentStatus,
  ShipmentStatusMap,
  WarehouseBiasedFinalSellPrice,
  WarehouseBiasedItem,
} from "models";
import { BillingTaxType, DeliveryType, SITE_TRANSFER_STATUS, SiteTransferData } from "models/order";
import { IUserDetailsInfo } from "teko-oauth2";
import { commonUtils, dateTimeUtils } from "utils";
import numberUtils from "utils/number";
import userUtils from "utils/user";
import { GRAY } from "../constants/color";
import { t } from "./i18n";

const { STAFF_BFF, IAM_ADMIN, SHOPPING_CART } = appConstants;
const { ORDER_CLONE, STAFF, CANCEL_OWNED_ORDERS, CANCEL_ALL_ORDERS } = roleConstants;
const { RETURN_REQUESTS, ORDERS, STOCK_REQUEST, USER } = resourceConstants;
const {
  CREATE,
  UPDATE,
  READ,
  CLONE,
  UPDATE_CUSTOMER,
  UPDATE_CONSULTANT,
  CONFIRM_HOLD,
  CHANGE_WAREHOUSE,
  SWAP_HOLD,
  MARK_PACKED,
  MARK_DELIVERED,
  MARK_DELIVERING,
  REPROCESS,
} = actionConstants;
const { permission, getPermissionCode } = userUtils;
const { formatNumber, thousandFormatterV2 } = numberUtils;
const { isValidDateTime } = dateTimeUtils;
const { isJsonString } = commonUtils;

const { SERVICE_QUANTITY } = commonConstants;

const canClone = (currentUser: IUserDetailsInfo, order: OrderDetails): boolean => {
  // User must have roles `staff-bff:order_clone` and `shopping-cart:staff`
  const hasRequiredRoles =
    currentUser.roles?.includes(getPermissionCode(permission(STAFF_BFF, ORDER_CLONE))) &&
    currentUser.roles?.includes(getPermissionCode(permission(SHOPPING_CART, STAFF)));

  // user & order's platform id must match
  const userPlatformId = Number.parseInt(lodashGet(currentUser, ["meta_data", "platformId"]));
  const isPlatformMatched = userPlatformId === order.platformId;

  // and order is allowed to clone
  const isAllowedToClone = order.allowedUserActions?.some((action) => action.actionCode === OrderActionCode.Clone);

  return hasRequiredRoles && isPlatformMatched && isAllowedToClone;
};

const canClonePreOMNI1095 = (userPermissions: string[]): boolean => {
  return userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, CLONE)));
};

const canPack = (status: ShipmentStatus, userPermissions: IUserDetailsInfo["permissions"]): boolean =>
  CAN_PACK_STATUSES.includes(status) &&
  userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, MARK_PACKED)));

const canDelivering = (status: ShipmentStatus, userPermissions: string[]): boolean => {
  const hasPermission = userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, MARK_DELIVERING)));
  return hasPermission && CAN_DELIVERING_STATUSES.includes(status);
};

const canShipped = (allowedUserActions: { actionCode: string }[] | undefined, userPermissions: string[]): boolean => {
  const hasPermission = userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, MARK_DELIVERED)));
  const markAsShippedActionCode = !!allowedUserActions?.some(
    (allowedAction) => allowedAction.actionCode === ActionCode.markAsShipped
  );
  return hasPermission && markAsShippedActionCode;
};

const canMarkAsProviderDelivered = (
  allowedUserActions: { actionCode: string }[] | undefined,
  userPermissions: string[]
): boolean => {
  const hasPermission = userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, MARK_DELIVERED)));
  const markAsProviderDeliveredActionCode = !!allowedUserActions?.some(
    (allowedAction) => allowedAction.actionCode === ShipmentActionCode.MarkAsProviderDelivered
  );
  return hasPermission && markAsProviderDeliveredActionCode;
};

const canComment = (allowedUserActions?: { actionCode: string }[]): boolean => {
  return !!allowedUserActions?.some((allowedAction) => allowedAction.actionCode === OrderActionCode.OrderComment);
};

const canConfirmWarehouse = (status: IOrderItem["status"], userPermissions: string[]): boolean => {
  const hasPermission = userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, UPDATE)));
  return hasPermission && status === OrderItemStatus.waitWarehouseConfirm;
};

const hasPermissionToConfirmHold = (userPermissions: IUserDetailsInfo["permissions"]) =>
  userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, CONFIRM_HOLD)));

const isInConfirmHoldStatus = (status: Shipment["shipmentStatus"]) =>
  status === OrderItemStatus.waitManualConfirm || status === OrderItemStatus.waitBackorderConfirm;

const canManualConfirm = (
  status: IOrderItem["status"] | Shipment["shipmentStatus"],
  userPermissions: IUserDetailsInfo["permissions"]
): boolean => {
  return hasPermissionToConfirmHold(userPermissions) && status === OrderItemStatus.waitManualConfirm;
};

const canConfirmBackorder = (
  status: IOrderItem["status"] | Shipment["shipmentStatus"],
  userPermissions: IUserDetailsInfo["permissions"]
): boolean => {
  return hasPermissionToConfirmHold(userPermissions) && status === OrderItemStatus.waitBackorderConfirm;
};

const isInChangeWarehouseStatus = (status: IOrderItem["status"] | Shipment["shipmentStatus"]) => {
  return [
    OrderItemStatus.backorderInsufficientStock,
    OrderItemStatus.waitBackorderConfirm,
    OrderItemStatus.waitManualConfirm,
    OrderItemStatus.waitTransfer,
    OrderItemStatus.backordering,
  ].includes(status as OrderItemStatus);
};

const hasPermissionToChangeWarehouse = (userPermissions: IUserDetailsInfo["permissions"]) =>
  userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, CHANGE_WAREHOUSE)));

const canChangeWarehouse = (
  status: IOrderItem["status"] | Shipment["shipmentStatus"],
  userPermissions: IUserDetailsInfo["permissions"]
): boolean => {
  return hasPermissionToChangeWarehouse(userPermissions) && isInChangeWarehouseStatus(status);
};

const hasPermissionToSwapHold = (userPermissions: IUserDetailsInfo["permissions"]) =>
  userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, SWAP_HOLD)));

const isInSwapHoldStatus = (status: IOrderItem["status"] | Shipment["shipmentStatus"]) => {
  return [
    OrderItemStatus.backorderInsufficientStock,
    OrderItemStatus.waitBackorderConfirm,
    OrderItemStatus.waitManualConfirm,
    OrderItemStatus.backordering,
    OrderItemStatus.waitTransfer,
  ].includes(status as OrderItemStatus);
};

const canSwapHold = (status: IOrderItem["status"] | Shipment["shipmentStatus"], userPermissions: string[]): boolean => {
  return hasPermissionToSwapHold(userPermissions) && isInSwapHoldStatus(status);
};

const canReturn_Pre1018 = (
  item: IOrderItem,
  userPermissions: string[],
  shipmentStatus?: IShipment["status"]
): boolean => {
  if (!item) return false;

  const hasPermission = userPermissions.includes(getPermissionCode(permission(STAFF_BFF, RETURN_REQUESTS, CREATE)));
  const isItemReturnable = ![OrderItemStatus.returned, OrderItemStatus.cancelled].includes(
    item.status as OrderItemStatus
  );

  if (shipmentStatus) {
    const isShipmentReturnable = ![
      ShipmentStatus.returned,
      ShipmentStatus.cancelled,
      ShipmentStatus.partialCancelled,
    ].includes(shipmentStatus);
    return hasPermission && isItemReturnable && isShipmentReturnable;
  }

  if (!item.status || item.status === OrderItemStatus.unspecified) {
    return false;
  }

  return !!item.status && hasPermission && isItemReturnable;
};

const hasPermissionToReturn = (userPermission: IUserDetailsInfo["permissions"]) =>
  userPermission.includes(getPermissionCode(permission(STAFF_BFF, RETURN_REQUESTS, CREATE)));

const canReturn = (shipmentStatus: Shipment["shipmentStatus"], userPermissions: string[]) => {
  return hasPermissionToReturn(userPermissions) && isShipmentReturnable(shipmentStatus);
};

const isShipmentReturnable = (shipmentStatus: Shipment["shipmentStatus"]) => {
  return ![ShipmentStatus.returned, ShipmentStatus.cancelled, ShipmentStatus.partialCancelled].includes(
    shipmentStatus as ShipmentStatus
  );
};

const canUpdateConsultant = (userPermissions: IUserDetailsInfo["permissions"]) => {
  return (
    userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, UPDATE_CONSULTANT))) &&
    userPermissions.includes(getPermissionCode(permission(IAM_ADMIN, USER, READ)))
  );
};

const canUpdateCustomer = (
  userPermissions: IUserDetailsInfo["permissions"],
  allowedUserActions?: MarketOrder["allowedUserActions"] | { actionCode: string }[]
) => {
  return (
    userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, UPDATE_CUSTOMER))) &&
    allowedUserActions?.some((allowedAction) => allowedAction.actionCode === ActionCode.updateCustomer)
  );
};

const canViewIncomingStock = (
  status: IOrderItem["status"] | Shipment["shipmentStatus"],
  userPermissions: string[]
): boolean => {
  const permissionViewIncomingStock = permission(STAFF_BFF, STOCK_REQUEST, READ);
  const hasPermission = userPermissions.includes(getPermissionCode(permissionViewIncomingStock));
  return hasPermission && status === OrderItemStatus.backordering;
};

const canCancelToReprocess = (allowedUserActions: { actionCode: string }[] | undefined, userPermissions: string[]) => {
  return (
    userPermissions.includes(getPermissionCode(permission(STAFF_BFF, ORDERS, REPROCESS))) &&
    allowedUserActions?.some((allowedAction) => allowedAction.actionCode === ShipmentActionCode.CancelToReprocess)
  );
};

const isAllItemSelectable = (item: IOrderItem, userPermissions: string[]): boolean => {
  return canConfirmWarehouse(item.status, userPermissions);
};

const isItemSelectablePre1018 = (
  item: IOrderItem,
  userPermissions: string[],
  shipmentStatus?: IShipment["status"]
): boolean => {
  const status = item.status;
  return (
    canManualConfirm(status, userPermissions) ||
    canConfirmBackorder(status, userPermissions) ||
    canChangeWarehouse(status, userPermissions) ||
    canSwapHold(status, userPermissions) ||
    canReturn_Pre1018(item, userPermissions, shipmentStatus)
  );
};

const isItemSelectable = (userPermissions: string[], status: Shipment["shipmentStatus"]): boolean => {
  return (
    canManualConfirm(status, userPermissions) ||
    canConfirmBackorder(status, userPermissions) ||
    canChangeWarehouse(status, userPermissions) ||
    canSwapHold(status, userPermissions) ||
    canReturn(status, userPermissions)
  );
};

const isOrderGroupBuySuccess = (items: IOrderItem[]): boolean => {
  return items.some((item) => !!item.isSuccessfulGroupBuy);
};

const isClearanceProduct = (item: IOrderItem | OrderCaptureLineItem | WarehouseBiasedItem): boolean => {
  return !!item.clearanceTypeTag;
};

const isInEmailFormat = (str: string): boolean => commonConstants.EMAIL_REGEX.test(str);

const isInPhoneFormat = (str: string): boolean => commonConstants.PHONE_REGEX.test(str);

const switchItemsToRouterItems: (order: MarketOrder) => MarketOrder = (order) => {
  return {
    ...order,
    items:
      order.routerItems.length > 0
        ? order.routerItems.map((item) => ({ ...item, source: OrderItemSource.FFR }))
        : order.items.map((item) => ({ ...item, source: OrderItemSource.OC })),
    shipments: order.shipments
      .filter((shipment) => !!shipment.id)
      .map((shipment) => ({
        ...shipment,
        items:
          shipment.routerItems.length > 0
            ? shipment.routerItems.map((item) => ({ ...item, source: OrderItemSource.FFR }))
            : shipment.items,
      })),
  };
};

const formatItemQuantity = (quantity: any) =>
  formatNumber(quantity, { maximumFractionDigits: MAX_ITEM_QUANTITY_DECIMAL_PLACES });

const formatItemQuantityV2 = (quantity: any) => thousandFormatterV2(quantity, MAX_ITEM_QUANTITY_DECIMAL_PLACES);

/**
 * This function checking replacedOrderId with string "0"
 * because currently cov is saved it in string in order to backward compatible
 * with orderId in other format
 */
const hasReplacedOrderId = (replacedOrderId: MarketOrder["replacedOrderId"]): boolean =>
  !!(replacedOrderId && replacedOrderId !== "0");

const formatConsultantInfo = (consultant?: IConsultant | Consultant | null) => {
  if (consultant) {
    const { name, customId, phone, email } = consultant;
    const extraData = customId || phone || email;

    if (name && extraData) return `${name} - ${extraData}`;
    return name || customId || phone || email || "";
  }
  return "";
};

const isOrderPrioritized = (order: OrderDetails) => {
  for (const shipment of order.shipments) {
    for (const item of shipment.warehouseItems) {
      if (item.priority > 0) return true;
    }
  }
  return false;
};

const transformToOrderDisplayStatus = (
  orderStatus: {
    confirmationStatus: ConfirmationStatus;
    paymentStatus?: PaymentStatus;
    fulfillmentStatus?: FulfillmentStatus;
    isHandover?: boolean;
    hasReturnedFullItems?: boolean;
  },
  isOMNI1058Enabled: boolean
): OrderDisplayStatus => {
  const statusMap = orderStatusMap.filter((obj) =>
    isOMNI1058Enabled ? !obj.isRemovedInOMNI1058 : !obj.isAddedInOMNI1058
  );

  for (const obj of statusMap) {
    const status = obj.status;
    const statusKeys = Object.keys(status);
    const filtered = Object.keys(orderStatus)
      .filter((key) => statusKeys.includes(key))
      .reduce((object, key) => {
        //@ts-ignore
        object[key] = orderStatus[key];
        return object;
      }, {});
    if (filtered && isEqual(status, filtered)) {
      return {
        displayName: obj.label,
        displayColor: obj.color,
        value: obj.value,
      };
    }
  }
  return { displayName: "", displayColor: "", value: "" };
};

const mapShipmentOptionsToShipmentStatus = (shipmentStatus?: string[]) => {
  const shipmentStatusFilter = { ...SHIPMENT_STATUS_MAP, ...HANDOVER_ORDER_STATUS_MAP };
  return !!shipmentStatus?.length
    ? shipmentStatus?.map((key) => shipmentStatusFilter[key].shipmentStatus || "").flat()
    : undefined;
};

const transformToWarehouseBiasedItems = (
  generalViewItems: GeneralViewItem[],
  shipmentStatus: Shipment["shipmentStatus"]
): WarehouseBiasedItem[] => {
  // TODO: Remove this after omni_252 go live
  const isFfItemIdExist = isFulfillmentItemIdExist(generalViewItems[0]?.fulfillmentItemId);
  const itemBySkuWarehouse = lodashGroupBy(generalViewItems, (item) =>
    isFfItemIdExist ? `${item.sku}-${item.warehouse}` : `${item.sku}-${item.warehouse}${item.holdItemId}`
  );
  return Object.keys(itemBySkuWarehouse).map((key) => {
    const items = itemBySkuWarehouse[key];
    const finalSellPrices: WarehouseBiasedFinalSellPrice[] = [];
    let totalQuantity = 0;
    let totalMissingQuantity = 0;
    let rowTotal = 0;
    let hasUnknownPriceValue = false;
    let siteTransfers: SiteTransferData[] = [];
    items.forEach(({ quantity, finalSellPrice, missingQuantity, siteTransferId, siteTransferStatus }) => {
      finalSellPrices.push({ quantity, finalSellPrice });
      totalQuantity += quantity;
      totalMissingQuantity += missingQuantity;
      rowTotal += quantity * finalSellPrice;
      siteTransfers.push({ id: siteTransferId, status: siteTransferStatus });

      if (finalSellPrice === UNKNOWN_PRICE_VALUE) {
        hasUnknownPriceValue = true;
      }
    });

    if (hasUnknownPriceValue) {
      rowTotal = UNKNOWN_PRICE_VALUE;
    }

    return {
      ...items[0],
      quantity: totalQuantity,
      missingQuantity: totalMissingQuantity,
      finalSellPrices,
      rowTotal,
      identifier: isFfItemIdExist
        ? `${items[0].sku}-${items[0].warehouse}-${shipmentStatus}-${items[0].sellerId}`
        : `${items[0].sku}-${items[0].warehouse}-${items[0].holdItemId}-${shipmentStatus}-${items[0].sellerId}`,
      type: ItemType.Item,
      siteTransfers,
      shipmentStatus,
    };
  });
};

const transformShipmentServicesToItems = (
  services: Service[],
  shipmentStatus: ShipmentStatus
): WarehouseBiasedItem[] => {
  if (shipmentStatus === ShipmentStatus.returned) {
    return [];
  }
  return services.map((service) => ({
    quantity: 1,
    sellerSku: service.sellerSku,
    sku: service.sellerSku,
    name: service.name,
    sellerName: service.sellerName,
    sellerId: service.sellerId,
    barcodes: [],
    clearanceTypeTag: "",
    clearanceTypeName: "",
    clearanceTypeDescription: "",
    clearanceBucketDiscountPercent: 0,
    sellPrice: service.sellPrice,
    finalSellPrices: [{ quantity: SERVICE_QUANTITY, finalSellPrice: service.finalSellPrice }],
    rowTotal: service.finalSellPrice,
    shipmentId: "",
    priority: 0,
    holdItemId: "",
    fulfillmentItemId: "",
    warehouse: "",
    siteId: 0,
    siteDisplayName: "",
    siteTransferId: "",
    siteTransferStatus: "",
    siteTransferExpectedIncomingDate: "",
    identifier: String(service.serviceId),
    type: ItemType.Service,
    missingQuantity: 0,
    uomName: "",
    siteTransfers: [],
  }));
};

const transformRawShipment = (shipment: RawShipment): Shipment => {
  return {
    ...shipment,
    generalItems: [...shipment.items],
    warehouseItems: [
      ...transformToWarehouseBiasedItems(shipment.items, shipment.shipmentStatus),
      ...transformShipmentServicesToItems(shipment.services, shipment.shipmentStatus as ShipmentStatus),
    ],
  };
};

const transformServices = (services: Service[], shipments: RawShipment[]): Service[] => {
  // Get services from shipments has status is RETURNED
  const servicesInReturnedShipment = shipments
    .map((shipment) => {
      if (shipment.shipmentStatus === ShipmentStatus.returned) {
        return shipment.services;
      }
      return [];
    })
    .flat();

  return [
    ...services.map((service) => ({
      ...service,
      identifier: getServiceKey(service),
    })),
    ...servicesInReturnedShipment.map((service) => ({
      ...service,
      identifier: getServiceKey(service),
    })),
  ];
};

const transformRawOrderDetails = (rawOrder: RawOrderDetails, isOmni1058Enabled: boolean): OrderDetails => {
  const {
    confirmationStatus,
    paymentStatus,
    isHandover,
    fulfillmentStatus,
    hasReturnedFullItems,
    shipments,
    services,
  } = rawOrder;
  return {
    ...rawOrder,
    shipments: shipments.map((shipment) => transformRawShipment(shipment)),
    status: transformToOrderDisplayStatus(
      {
        confirmationStatus,
        paymentStatus,
        isHandover,
        fulfillmentStatus,
        hasReturnedFullItems,
      },
      isOmni1058Enabled
    ),
    services: transformServices(services, shipments),
  };
};

const transformToPriceBiasedItems = (
  shipmentItems: WarehouseBiasedItem[],
  generalViewItems: GeneralViewItem[]
): PriceBiasedItem[] => {
  // TODO: Remove this after omni_252 go live
  const isFfItemIdExist = isFulfillmentItemIdExist(generalViewItems[0]?.fulfillmentItemId);
  const skuWarehouseMap = lodashGroupBy(
    generalViewItems,
    (item) => `${item.sku}-${item.warehouse}-${isFfItemIdExist ? "" : item.holdItemId}`
  );
  const priceBiasedServices: PriceBiasedItem[] = [];
  const skuPriceMap: { [key: string]: PriceBiasedItem } = {};

  shipmentItems.forEach((shipmentItem) => {
    if (shipmentItem.type === ItemType.Item) {
      const generalItems =
        skuWarehouseMap[
          `${shipmentItem.sku}-${shipmentItem.warehouse}-${isFfItemIdExist ? "" : shipmentItem.holdItemId}`
        ];
      generalItems.forEach((item) => {
        const priceKey = `${item.sku}-${item.finalSellPrice}`;
        if (!skuPriceMap[priceKey]) {
          skuPriceMap[priceKey] = {
            name: item.name,
            sellerId: item.sellerId,
            finalSellPrice: item.finalSellPrice,
            quantity: item.quantity,
            sku: item.sku,
            sellerSku: item.sellerSku,
            shipmentId: item.shipmentId,
            siteId: item.siteId,
            identifier: priceKey,
            type: ItemType.Item,
          };
        } else {
          skuPriceMap[priceKey].quantity += item.quantity;
        }
      });
    } else {
      priceBiasedServices.push({
        name: shipmentItem.name,
        sellerId: shipmentItem.sellerId,
        finalSellPrice: shipmentItem.finalSellPrices[0].finalSellPrice,
        quantity: SERVICE_QUANTITY,
        sku: shipmentItem.sku,
        sellerSku: shipmentItem.sellerSku,
        shipmentId: shipmentItem.shipmentId,
        siteId: shipmentItem.siteId,
        identifier: shipmentItem.identifier,
        type: ItemType.Service,
      });
    }
  });

  return [...Object.values(skuPriceMap), ...priceBiasedServices];
};
// TODO: Remove this func when OMNI-1058 fully releases;
const getOrderStatusFilter = (isOMNI1058Enabled: boolean): OrderStatusFilter => {
  if (!isOMNI1058Enabled)
    return {
      ...ORDER_STATUS_FILTER,
      [OrderStatus.returned]: {
        name: t("OrderStatusLabel.Returned"),
        params: {
          fulfillmentStatus: [FulfillmentStatus.returned],
        },
      },
      [OrderStatus.delivered]: {
        name: t("OrderStatusLabel.Delivered"),
        params: {
          isDelivered: true,
        },
      },
    };
  return ORDER_STATUS_FILTER;
};

const getShipmentStatusFilterForSeller = (isOMNI1058Enabled: boolean) => {
  const { OTHER, ...rest } = SHIPMENT_STATUS_MAP;
  if (!isOMNI1058Enabled) {
    rest[ShipmentStatus.readyPickup] = {
      name: t("ShipmentStatusLabel.ReadyPickupPre1058"),
      shipmentStatus: [ShipmentStatus.readyPickup],
      color: GRAY.main,
      useForFilter: true,
    };
    delete rest[ShipmentStatus.deliveredAtAgency];
  }
  return { ...rest, ...HANDOVER_ORDER_STATUS_MAP, OTHER };
};

const getShipmenStatusFilterForPlatform = (isOMNI1058Enabled: boolean) => {
  if (!isOMNI1058Enabled) {
    delete SHIPMENT_STATUS_MAP[ShipmentStatus.deliveredAtAgency];
    return {
      ...SHIPMENT_STATUS_MAP,
      [ShipmentStatus.readyPickup]: {
        name: t("ShipmentStatusLabel.ReadyPickupPre1058"),
        shipmentStatus: [ShipmentStatus.readyPickup],
        color: GRAY.main,
        useForFilter: true,
      },
    };
  }
  return SHIPMENT_STATUS_MAP;
};

// TODO: Remove this after omni_252 go live
const isFulfillmentItemIdExist = (fulfillmentItemId?: string) => {
  return !!fulfillmentItemId && fulfillmentItemId !== "0";
};

const getServiceKey = (service: Service) => {
  return `${service.sellerId}-${service.serviceId}`;
};

const formatOrderItemPrice = (value: number, precision?: number) => {
  if (value === UNKNOWN_PRICE_VALUE) {
    return "-";
  }
  return numberUtils.formatPriceV2(value, precision);
};

const validateSameOrdersCustomer = (orders: OrderTableRecord[]) => {
  const orderCustomers = orders.map((order) => order.contact.customer);
  return orderCustomers.every((customer) => {
    if (!customer || !customer?.id) return false;
    return customer?.id === orderCustomers[0]?.id;
  });
};

const validateSameLastWarehouseExportAt = (orders: OrderTableRecord[]) => {
  const lastWarehouseExportAtDates = orders.map((order) => order.order.lastWarehouseExportAt);
  return lastWarehouseExportAtDates.every((date) => {
    if (
      !date ||
      !lastWarehouseExportAtDates[0] ||
      !isValidDateTime(date) ||
      !isValidDateTime(lastWarehouseExportAtDates[0])
    )
      return false;
    return dayjs(lastWarehouseExportAtDates[0]).isSame(date, "day");
  });
};

const isShipmentStatusInAllowedStatusesToBePrinted = (shipmentStatus: Shipment["shipmentStatus"]): boolean => {
  return CAN_PRINT_SHIPMENT_STATUSES.includes(shipmentStatus as ShipmentStatus);
};

const isOrderHasPrintableStatus = (order: OrderDetails): boolean => {
  // Check if all shipments has status is allowed to be printed
  const realShipmentStatuses = order.shipments
    .filter((shipment) => !!shipment.shipmentId)
    .map((shipment) => shipment.shipmentStatus);
  const isShipmentStatusesAllowedToBePrinted = realShipmentStatuses.every((status) =>
    isShipmentStatusInAllowedStatusesToBePrinted(status)
  );

  // Check if all items has status is allowed to be printed
  // Since item's status will be the virtual shipment's status => just need to extract the virtual shipment's status
  const virtualShipmentStatuses = order.shipments
    .filter((shipment) => !shipment.shipmentId)
    .map((shipment) => shipment.shipmentStatus);

  const isVirtualShipmentStatusesAllowedToBePrinted = virtualShipmentStatuses.every(
    (status) => status === OrderItemStatus.cancelled
  );

  const isShipmentExist = !!realShipmentStatuses.length || !!virtualShipmentStatuses.length;

  return isShipmentExist && isShipmentStatusesAllowedToBePrinted && isVirtualShipmentStatusesAllowedToBePrinted;
};

const getShipmentStatus = (
  shipmentStatus: string,
  deliveryType: DeliveryType | undefined,
  isOMNI1058Enabled: boolean
) => {
  const shipmentStatusMap: ShipmentStatusMap = { ...SHIPMENT_STATUS_MAP, ...HANDOVER_ORDER_STATUS_MAP };
  if (!isOMNI1058Enabled) {
    shipmentStatusMap[ShipmentStatus.readyPickup] = {
      name: t("ShipmentStatusLabel.ReadyPickupPre1058"),
      shipmentStatus: [ShipmentStatus.readyPickup],
      color: GRAY.main,
      useForFilter: true,
    };
    delete shipmentStatusMap[ShipmentStatus.deliveredAtAgency];
  }

  if (isOMNI1058Enabled && shipmentStatus === ShipmentStatus.delivering && deliveryType === DeliveryType.pickup) {
    return _.pick(shipmentStatusMap[CalculatedShipmentStatus.deliveringToAgency], ["name", "color"]);
  }
  return _.pick(shipmentStatusMap[shipmentStatus], ["name", "color"]);
};

const isShipmentStatusUpdated = (
  latestOrder: OrderDetails,
  expectedValue: { [shipmentId: string]: ShipmentStatus }
) => {
  return latestOrder.shipments.some((s) => s.shipmentStatus === expectedValue[s.shipmentId]);
};

const canCancel = (currentUser: IUserDetailsInfo, order: OrderDetails): boolean => {
  // User must have role `staff-bff:cancel_owned_orders` or `staff-bff:cancel_all_orders`
  const hasRequiredRoles =
    (currentUser.roles?.includes(getPermissionCode(permission(STAFF_BFF, CANCEL_OWNED_ORDERS))) &&
      order.creator?.id === currentUser.sub) ||
    currentUser.roles?.includes(getPermissionCode(permission(STAFF_BFF, CANCEL_ALL_ORDERS)));

  // and order is allowed to Cancel
  const isAllowedToCancel = order.allowedUserActions?.some((action) => action.actionCode === OrderActionCode.Cancel);

  return hasRequiredRoles && isAllowedToCancel;
};

const isSiteTransferDelivering = (siteTransferStatus: string) => {
  return SITE_TRANSFER_STATUS.DELIVERING === (siteTransferStatus as SITE_TRANSFER_STATUS);
};

const getBillingTaxTypeOptions = (value?: BillingTaxType) => {
  switch (value) {
    case BillingTaxType.TAX_FREE_INVOICE: {
      return [
        {
          value: BillingTaxType.TAX_FREE_INVOICE,
          label: t("BillingTaxType.TaxFree"),
        },
      ];
    }
    case BillingTaxType.TAX_GIFT_INVOICE: {
      return [
        {
          value: BillingTaxType.TAX_GIFT_INVOICE,
          label: t("BillingTaxType.GiftItems"),
        },
      ];
    }
    default: {
      return [
        {
          value: BillingTaxType.VAT,
          label: t("BillingTaxType.VAT"),
        },
        {
          value: BillingTaxType.TAX_REFUND_INVOICE,
          label: t("BillingTaxType.TaxRefund"),
        },
      ];
    }
  }
};

const getOrderNote = (note: any): string => {
  // Check if string is a valid JSON
  if (isJsonString(note)) {
    // Parse the JSON and check if there are any note property within the parsed object
    const obj = JSON.parse(note);
    // If note is null or undefined return empty string
    if (obj === null || obj === undefined) {
      return "";
    }
    // If the note value within the JSON object is valid (can be 0 or empty string)
    else if (obj?.["note"] !== undefined && obj?.["note"] !== null) {
      return obj.note;
    }
  } else if (!note) {
    return "";
  }
  return note;
};

export default {
  canClone,
  canClonePreOMNI1095,
  canPack,
  canDelivering,
  canShipped,
  canManualConfirm,
  canMarkAsProviderDelivered,
  canChangeWarehouse,
  canComment,
  canConfirmBackorder,
  canConfirmWarehouse,
  canSwapHold,
  canReturn_Pre1018,
  canReturn,
  canUpdateConsultant,
  canUpdateCustomer,
  canCancelToReprocess,
  canViewIncomingStock,
  isAllItemSelectable,
  isClearanceProduct,
  isFulfillmentItemIdExist,
  isInChangeWarehouseStatus,
  isInConfirmHoldStatus,
  isInSwapHoldStatus,
  isItemSelectablePre1018,
  isItemSelectable,
  isInEmailFormat,
  isInPhoneFormat,
  isOrderGroupBuySuccess,
  isOrderPrioritized,
  isOrderHasPrintableStatus,
  isShipmentReturnable,
  hasPermissionToChangeWarehouse,
  hasPermissionToConfirmHold,
  hasPermissionToReturn,
  hasPermissionToSwapHold,
  hasReplacedOrderId,
  formatItemQuantity,
  formatItemQuantityV2,
  formatConsultantInfo,
  formatOrderItemPrice,
  transformToOrderDisplayStatus,
  transformRawOrderDetails,
  transformToWarehouseBiasedItems,
  transformToPriceBiasedItems,
  transformRawShipment,
  validateSameOrdersCustomer,
  validateSameLastWarehouseExportAt,
  getOrderStatusFilter,
  getShipmentStatusFilterForSeller,
  getShipmenStatusFilterForPlatform,
  getShipmentStatus,
  mapShipmentOptionsToShipmentStatus,
  switchItemsToRouterItems,
  isShipmentStatusUpdated,
  canCancel,
  isSiteTransferDelivering,
  getBillingTaxTypeOptions,
  getOrderNote,
};
