import { PaginationTypeTotal } from "./common";

export interface IFileUploadHistoryQueryParams {
  offset?: number;
  limit?: number;
  type?: number;
  sellerId?: number;
}

export interface IFileUploadHistory {
  id: number;
  actualFileName: string;
  status: 0 | 1 | 2 | 3 | 4 | 5;
  updatedBy: string;
  updatedAt: string;
}

export enum UPLOAD_TYPE {
  document = "doc",
  image = "image",
}

export enum FileProcessStatus {
  init = "INIT",
  processing = "PROCESSING",
  failed = "FAILED",
  finished = "FINISHED",
}
export interface IFileProcessHistory {
  processingFileId: number;
  fileUrl: string;
  fileDisplayName: string;
  createdBy: string;
  createdAt: number; //timestamp
  status: FileProcessStatus; // enum INIT, PROCESSING, FAILED, SUCCESS
  statsTotalSuccess: number;
  statsTotalRow: number;
  resultFileUrl: string;
}

export interface IGetFileProcessHistoryParams {
  page?: number;
  size?: number;
}

export interface ICommonFileProcessHistoryResponse {
  processingFiles: IFileProcessHistory[];
  pagination: PaginationTypeTotal;
}
export interface IFileProcessingHistory {
  processingFileId: number;
  fileUrl: string;
  fileDisplayName: string;
  createdBy: string;
  createdAt: number; //timestamp
  status: FileProcessStatus; // enum INIT, PROCESSING, FAILED, SUCCESS
  statsTotalSuccess: number;
  statsTotalRow: number;
  statsTotalProcessed: number;
  resultFileUrl: string;
  finishedAt?: number;
}

export interface IGetFileProcessingHistoryResponse {
  processingFiles: IFileProcessingHistory[];
  pagination: PaginationTypeTotal;
}
