import { appConfig } from "app-config";
import { EXTERNAL_URLS } from "constants/index";
import dayjs from "dayjs";
import get from "lodash/get";
import { ExportHistoryType, IRoute } from "models";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "rc-picker/node_modules/dayjs";
import { OptionData, OptionGroupData } from "rc-select/lib/interface";
import { FilterFunc } from "rc-select/lib/interface/generator";
import { t } from "utils/i18n";

const { ticketClients, externalLink } = appConfig;

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const downloadFile = (url: string, name: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
};

const isCurrentPathUseSellerSwitch = (currentPath: string, listPaths: IRoute[]): boolean => {
  for (const pathName of listPaths) {
    const isDynamicPath = pathName.path.includes(":");
    const regex = new RegExp(pathName.path.replace(/:\w+/, "\\w+$"));
    const result = isDynamicPath ? currentPath.match(regex) : pathName.path === currentPath;
    if (result) return !!pathName.isUseSellerSwitch;
  }
  return false;
};

const filterByRenderedContent: FilterFunc<OptionData | OptionGroupData> = (input, option) => {
  /* Filter value rendered in Select.Options and options.label
    input: value to search
    option: value of Select.Options or options.label
    option.children: value rendered in Select.Options
  */
  const lowerCaseInput = input.toLowerCase();
  if (option?.children) {
    const childrenInString = Array.isArray(option.children) ? option.children.join("") : String(option.children);
    return childrenInString.toLowerCase().indexOf(lowerCaseInput) >= 0;
  } else if (option?.label) {
    return option.label.toString().toLowerCase().indexOf(lowerCaseInput) >= 0;
  }

  return false;
};

const filterByBothValueAndRenderedContent: FilterFunc<OptionData | OptionGroupData> = (input, option) => {
  const lowerCaseInput = input.toLowerCase();
  if (option?.label && option?.value) {
    return (
      String(option.label).toLowerCase().indexOf(lowerCaseInput) >= 0 ||
      option.value.toString().toLowerCase().indexOf(lowerCaseInput) >= 0
    );
  } else if (option?.children && option?.value) {
    const childrenInString: string = Array.isArray(option.children)
      ? option.children.join("")
      : String(option.children);
    return (
      childrenInString.toLowerCase().indexOf(lowerCaseInput) >= 0 ||
      option.value.toString().toLowerCase().indexOf(lowerCaseInput) >= 0
    );
  }
  return false;
};

const delayTime = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Get the correct ticket domain by current location
 * @returns the domain to redirect Ticket Management
 */
export const getTicketDomain = (currentDomain: string): string => get(ticketClients, [currentDomain]);

export const rangeDatePickerValidation = (
  range?: RangeValue<Dayjs>,
  limit?: number
): { startDate?: EventValue<Dayjs>; endDate?: EventValue<Dayjs> } => {
  let startDate, endDate;
  if (!Array.isArray(range)) return { startDate, endDate };
  if (!range[0] || !range[1]) return { startDate: range[0], endDate: range[1] };
  if (limit && !(range[1].diff(range[0], "day") + 1 <= limit)) {
    return { startDate, endDate };
  }
  return { startDate: range[0], endDate: range[1] };
};

const quickDateRanges = (nDaysOpts: number[]) =>
  Object.fromEntries([
    [t("Today"), [dayjs().startOf("day"), dayjs().endOf("day")]],
    ...nDaysOpts.map((nDays) => {
      return [
        t("LastXDays", { nDays }),
        [
          dayjs()
            .subtract(nDays - 1, "day")
            .startOf("day"),
          dayjs().endOf("day"),
        ],
      ];
    }),
  ]);

const getEnumKeyByValue = <EnumKey extends string, EnumValue extends string | number>(
  myEnum: { [key in EnumKey]: EnumValue },
  enumValue: EnumValue
): EnumKey | undefined => {
  const resKey = (Object.keys(myEnum) as EnumKey[]).find((key) => myEnum[key] === enumValue);
  return resKey;
};

const capitalize = (str: string): string => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => {
      const firstLetter = word.split("")[0].toUpperCase();
      const otherLetters = word.substring(1).toLowerCase();
      return `${firstLetter}${otherLetters}`;
    })
    .join("");
};

const removeSpecialChar = (str: string) => str?.replace(/[^a-zA-Z ]/g, " ");

const chainMultipleFunctions = (...functions: Function[]) => {
  return function (...args: any[]) {
    return functions.reduce((result, func) => [func.call(functions, ...result)], args)[0];
  };
};

const validateDateRangeList = (dateRangeLimit: number, ...ranges: (RangeValue<Dayjs> | undefined)[]) => {
  if (!ranges?.length) return false;
  for (let i = 0; i < ranges.length; i++) {
    const { startDate, endDate } = rangeDatePickerValidation(ranges[i], dateRangeLimit);
    if (!!startDate && !!endDate) return true;
  }
  return false;
};

const navigateToExportHistory = (exportHistoryType: ExportHistoryType, parentOrigin: string | undefined) => {
  const href = parentOrigin
    ? `${parentOrigin}/reports/export-history/${exportHistoryType}`
    : `${externalLink?.supplyChain}${EXTERNAL_URLS.Supplychain_ExportHistory}/${exportHistoryType}`;
  window.open(href, "_blank");
};

const isAllValuesEmpty = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== null && value !== undefined && value !== "") {
        return false;
      }
    }
  }
  return true;
};

const overFlowThousandNumber = (number: number) => {
  return number > 999 ? "999+" : number;
};

const typeColorQty = (number: number) => {
  return number === 0 ? "secondary" : undefined;
};

const forcePositiveNumber = (number: number) => {
  return number > 0 ? number : 0;
};

const isJsonString = (input: any) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  getWindowDimensions,
  downloadFile,
  isCurrentPathUseSellerSwitch,
  filterByRenderedContent,
  filterByBothValueAndRenderedContent,
  delayTime,
  getTicketDomain,
  rangeDatePickerValidation,
  quickDateRanges,
  getEnumKeyByValue,
  chainMultipleFunctions,
  capitalize,
  validateDateRangeList,
  removeSpecialChar,
  navigateToExportHistory,
  isAllValuesEmpty,
  overFlowThousandNumber,
  typeColorQty,
  forcePositiveNumber,
  isJsonString,
};
