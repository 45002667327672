export const actionConstants = {
  CANCEL: "update:cancel",
  CANCEL_SERVICE_FEE: "update:cancel_service_fee",
  CHANGE_WAREHOUSE: "update:item:change_warehouse",
  CLONE: "update:clone",
  CONFIRM_HOLD: "update:item:confirm_hold",
  CREATE: "create",
  CREATE_SITE_AND_WAREHOUSE: "create:site_and_warehouse",
  CREATE_PARTIAL_SHIPMENT: "update:item:create_partial_shipment",
  DELETE: "delete",
  DELETE_SKU_REQUEST: "delete:sku_request",
  EDIT_QUOTATION: "create:import_to_cart",
  EXPORT_SALES_REPORT: "read:export_sales_report",
  FORCE_CREATE_SHIPMENT: "update:force_create_shipment",
  MARK_DELIVERED: "update:shipment:mark_delivered",
  MARK_DELIVERING: "update:shipment:mark_delivering",
  MARK_PACKED: "update:shipment:mark_packed",
  PRINT_LABEL: "read:label",
  PRINT_ORDER: "read:print_order",
  PRINT_MULTIPLE_ORDERS: "read:print_multiple_orders",
  READ: "read",
  READ_EINVOICE: "read:einvoice",
  READ_IMBALANCED_STOCKS: "read:imbalanced_stocks",
  READ_REVENUE_REPORT: "read:revenue_report",
  READ_SITE_AND_WAREHOUSE: "read:site_and_warehouse",
  REPROCESS: "update:shipment:reprocess",
  SWAP_HOLD: "update:item:swap_hold",
  UPDATE: "update",
  UPDATE_BILLING_INFO: "update:billing_info",
  UPDATE_CONSULTANT: "update:consultant",
  UPDATE_CUSTOMER: "update:customer",
  UPDATE_HOLD_PRIORITY: "update:update_hold_priority",
  UPDATE_SITE_AND_WAREHOUSE: "update:site_and_warehouse",
  VIEW_SALES_REPORT: "read:view_sales_report",
  VIEW_ORDER_HISTORY: "read:change_history",
  EXPORT_ORDER_HEADER_REPORT: "read:order_header_report",
  EXPORT_ORDER_DETAIL_REPORT: "read:order_detail_report",
  EXPORT_SELLER_REPORT: "read:seller_report",
  READ_CLEARANCE_REQUEST: "read:clearance_request",
  UPDATE_ACTIVATE: "update:activate",
  READ_EXPORT: "read:export",
  READ_DISCOUNT_APPROVED_REPORT: "read:discount_approved_report",
  READ_POR_INVOICE: "read:por_invoice",
  CREATE_POR_INVOICE: "create:por_invoice",
  PRINT_RETURN_REQUEST: "read:print_return_request",
  UPDATE_DEACTIVATE_SITE: "update:deactivate_site",
};

export const appConstants = {
  APL: "apl",
  AS: "as",
  CATALOG: "catalog",
  FMS: "fms",
  IAM_ADMIN: "iam_admin",
  ONS_BFF: "ons-bff",
  OP: "op",
  STAFF_BFF: "staff-bff",
  STAFF_FE: "staff_fe",
  TICKET_BFF: "ticket-bff",
  SHOPPING_CART: "shopping-cart",
};

export const resourceConstants = {
  BANKING: "banking",
  BILL_OF_LADING: "bol",
  CLEARANCE_REQUEST: "clearance_request",
  CREDIT_APPROVAL_AUTHORITY: "credit_approval_authority",
  CREDIT_APPROVAL_CONFIGS: "credit_approval_configs",
  DELIVERY_REQUEST: "delivery_request",
  DISCOUNT_APPROVAL_CONFIGS: "discount_approval_configs",
  INVENTORY: "inventory",
  INVOICE: "invoice",
  ISSUES: "issues",
  LOYALTY_COMPUTATION_CONFIGS: "loyalty_computation_configs",
  ORDERS: "orders",
  ORDER_HEALTH: "order_health",
  PAYMENT_RECONCILE_REPORT: "payment_reconcile_report",
  PENDING_SALES_ORDERS: "pending_sales_orders",
  PERIOD_REPORT: "period_report",
  PRODUCTS: "products",
  PURCHASE_INVOICES: "purchase_invoices",
  REFUND_REQUEST: "refund_request",
  REPORTS: "reports",
  RETURN_REASONS: "return_reasons",
  RETURN_REQUESTS: "return_requests",
  STOCK_REQUEST: "stock_request",
  STOCK_TRANSFER_OUT_REPORT: "stock_transfer_out_report",
  SYSTEM_ADMIN: "system_admin",
  TOOLS_IMPORT: "tools_import",
  USER: "user",
  SITE_LEAD_TIME: "site_lead_time",
  EXPORT_HISTORY: "export_history",
  AUDIT_LOG: "audit-log",
  ORDER_NOTIFICATIONS: "order-notifications",
  ACCOUNTING: "accounting",
  APPROVAL_DEBT_CONFIG: "approval_debt_config",
};

export const roleConstants = {
  ACCOUNTANT: "accountant",
  ACCOUNTANT_MANAGER: "accountant_manager",
  ACCOUNTANT_READONLY: "accountant_readonly",
  BRANCH_ACCOUNTANT: "branch_accountant",
  CASHIER: "cashier",
  EXECUTIVE: "executive",
  MANAGER: "manager",
  MARKETPLACE_ORDER_READONLY: "marketplace_order_readonly",
  SELLER_MANUAL_INVENTORY: "seller_manual_inventory",
  SELLER_ORDER_READONLY: "seller_order_readonly",
  SYNC_UP_DATA: "sync_up_data",
  ORDER_CLONE: "order_clone",
  STAFF: "staff",
  CANCEL_OWNED_ORDERS: "cancel_owned_orders",
  CANCEL_ALL_ORDERS: "cancel_all_orders",
  OM_BATCH_UPDATE: "om_batch_update",
};
