import accounting from "accounting";
import { commonConstants, localStorageConstants } from "constants/index";
import { INumberDisplayFormat } from "models";
import { iframeUtils } from "utils";

accounting.settings.currency.symbol = "₫";
accounting.settings.currency.format = "%v %s";
accounting.settings.currency.precision = 0;

const formatPrice = (value: any, precision?: number) => {
  if (value === null || value === undefined) return "";
  if (precision) return accounting.formatMoney(value, { precision });
  return accounting.formatMoney(value);
};

const formatNumber = (value: any, options?: Intl.NumberFormatOptions) => {
  if (value === null || value === undefined) return "";
  return new Intl.NumberFormat("en-EN", options).format(value);
};

const roundingNumber = (value: number | undefined, place?: number): number | undefined => {
  if (!value) return value;
  if (place) {
    return Math.round((value + Number.EPSILON) * Math.pow(10, place)) / Math.pow(10, place);
  } else {
    return Math.round(value + Number.EPSILON);
  }
};

const isNumber = (value: any) => typeof value === "number" && !isNaN(value);

const thousandFormatter = (value: number | string | undefined, precision?: number): string => {
  if (value === undefined) return "";
  const parts = value.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (precision && !!parts[1]) {
    parts[1] = parts[1].slice(0, precision);
  }

  return parts.join(".");
};

const thousandParser = (value: string | undefined, precision?: number): React.Key => {
  if (value === undefined) return "";
  const parts = value.split(".");

  parts[0] = parts[0]?.replace(/(,*)/g, "") || "";
  if (precision && !!parts[1]) {
    parts[1] = parts[1].slice(0, precision);
  }

  return parts.join(".");
};

const getNumberDisplayFormat = (): INumberDisplayFormat => {
  const allowedToUseNumberDisplayConfig = localStorage.getItem(localStorageConstants.FLAG_ECOM_489) === "true";

  return allowedToUseNumberDisplayConfig
    ? iframeUtils.getDataFromIframe()?.numberDisplayFormat || commonConstants.DEFAULT_NUMBER_DISPLAY_FORMAT
    : commonConstants.OM_DEFAULT_NUMBER_DISPLAY_FORMAT;
};

const thousandFormatterV2 = (value: number | string | undefined, precision?: number): string => {
  if (!value && typeof value != "number") return "";
  if (!!precision || precision === 0) {
    value = parseFloat(value.toString()).toFixed(precision);
  }
  const { decimalSeparator, thousandSeparator } = getNumberDisplayFormat();

  const parts = value.toString().split(".");

  parts[0] = parts[0].replace(commonConstants.THOUSAND_FORMAT_REGEX, thousandSeparator);

  if (precision && !!parts[1]) {
    parts[1] = parseFloat(`0.${parts[1]}`).toString().slice(2);
  }

  return parts.filter(Boolean).join(decimalSeparator);
};

const formatPriceV2 = (value: any, precision?: number) => {
  if (!value && typeof value != "number") return "";
  return `${thousandFormatterV2(value, precision)} ${commonConstants.VND_CURRENCY.symbol}`;
};

const isNumberString = (value: string) => {
  return commonConstants.INTEGER_OR_FLOAT_REGEX.test(value);
};

const isNumberStringWithDotInTheEnd = (value: string) => {
  return isNumberString(value.slice(0, -1)) && value.endsWith(commonConstants.DEFAULT_DECIMAL_SEPARATOR);
};

const toFixedByPrecision = (value: string, precision: number): string => {
  if (
    (!!precision || precision === 0) &&
    value.split(commonConstants.DEFAULT_DECIMAL_SEPARATOR)?.[1]?.length > precision
  ) {
    const base = 10 ** precision;
    return (Math.round(parseFloat(value) * base) / base).toFixed(precision);
  }
  return value;
};
export default {
  formatPrice,
  formatNumber,
  roundingNumber,
  isNumber,
  thousandFormatter,
  thousandParser,
  thousandFormatterV2,
  formatPriceV2,
  getNumberDisplayFormat,
  isNumberString,
  toFixedByPrecision,
  isNumberStringWithDotInTheEnd,
};
