import { FileDoneOutlined } from "@ant-design/icons";
import { flagConstants } from "constants/index";
import { actionConstants, appConstants, resourceConstants, roleConstants } from "constants/permission";
import {
  withAssignedSingleSeller,
  withAuthorizedPlatformsOrSellers,
  withFeatureFlag,
  withPlatformPermission,
  withSellerOrderPermission,
  withSinglePlatformId,
} from "hocs";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_BFF } = appConstants;
const {
  MARKETPLACE_ORDER_READONLY,
  ACCOUNTANT,
  ACCOUNTANT_READONLY,
  SELLER_ORDER_READONLY,
  SYNC_UP_DATA,
  OM_BATCH_UPDATE,
} = roleConstants;
const { RETURN_REQUESTS, PENDING_SALES_ORDERS } = resourceConstants;
const { READ } = actionConstants;
const { EXTENDED_FLAG_KEYS, FLAG_KEYS } = flagConstants;

// Pages
const MarketOrderDetail = lazy(() => import("pages/OrderDetail/MarketOrderDetail"));
const OrderDetailsForPlatform = lazy(() => import("pages/OrderDetail/OrderDetailsForPlatform"));
const MarketOrderList_Pre1018 = lazy(() => import("pages/MarketOrderList_Pre1018"));
const MarketOrderList = lazy(() => import("pages/MarketOrderList"));
const SearchOrderList = lazy(() => import("pages/SearchOrderList"));
const SellerOrderList_Pre1018 = lazy(() => import("pages/SellerOrderList_Pre1018"));
const SellerOrderList = lazy(() => import("pages/SellerOrderList"));
const SellerOrderDetail = lazy(() => import("pages/OrderDetail/SellerOrderDetail"));
const OrderDetailsForSeller = lazy(() => import("pages/OrderDetail/OrderDetailsForSeller"));
const ReturnRequestList = lazy(() => import("pages/ReturnRequestList"));
const QuotationList = lazy(() => import("pages/QuotationList"));
const SyncUpData = lazy(() => import("pages/SyncUpData"));
const ImportShipmentsStatus = lazy(() => import("pages/ImportShipmentsStatus"));

export default [
  // Parent menu
  {
    path: "/orders",
    name: t("Orders.Root"),
    icon: FileDoneOutlined,
    children: [
      "/orders/marketplace",
      "/orders/marketplace/search",
      "/orders/seller",
      "/orders/return-requests",
      "/orders/customer-quotations",
      "/orders/sync",
      "/orders/import-shipments-status",
    ],
  },
  // Sub menus
  // -------------------- Order List------------------------------
  {
    exact: true,
    path: "/orders/marketplace",
    name: t("Orders.Marketplace"),
    component: withFeatureFlag(
      withPlatformPermission(MarketOrderList_Pre1018, t("Orders.Root")),
      withPlatformPermission(MarketOrderList, t("Orders.Root")),
      EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_LIST_FOR_PLATFORM.key
    ),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      // below roles will be removed after https://jira.teko.vn/browse/UX4ERP-1845
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
  },
  {
    exact: true,
    path: "/orders/seller",
    name: t("Orders.Seller"),
    component: withFeatureFlag(
      SellerOrderList_Pre1018,
      withAssignedSingleSeller(SellerOrderList),
      EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_LIST_FOR_SELLER.key
    ),
    permissions: [permission(STAFF_BFF, SELLER_ORDER_READONLY)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/orders/marketplace/search",
    name: t("Orders.Search"),
    component: withPlatformPermission(SearchOrderList, t("Orders.Root")),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      // below roles will be removed after https://jira.teko.vn/browse/UX4ERP-1845
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
    flagKey: EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_LIST_FOR_PLATFORM.key,
  },
  // -------------------- Order Detail ------------------------------
  {
    exact: true,
    path: "/orders/marketplace/:orderId",
    name: t("Orders.Detail"),
    component: withFeatureFlag(
      withPlatformPermission(MarketOrderDetail, t("Orders.Detail")),
      withPlatformPermission(OrderDetailsForPlatform, t("Orders.Detail")),
      EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_DETAIL_FOR_PLATFORM.key
    ),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      // below roles will be removed after https://jira.teko.vn/browse/UX4ERP-1845
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
  },
  {
    exact: true,
    path: "/orders/seller/:sellerId/:orderId",
    name: t("Orders.Detail"),
    component: withFeatureFlag(
      withSellerOrderPermission(SellerOrderDetail),
      withAssignedSingleSeller(OrderDetailsForSeller),
      EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_DETAIL_FOR_SELLER.key
    ),
    permission: [permission(STAFF_BFF, SELLER_ORDER_READONLY)],
  },
  {
    exact: true,
    path: "/orders/return-requests",
    name: t("Orders.ReturnRequests"),
    component: withAuthorizedPlatformsOrSellers(ReturnRequestList, t("Orders.ReturnRequests")),
    permissions: [permission(STAFF_BFF, RETURN_REQUESTS, READ)],
  },
  {
    exact: true,
    path: "/orders/customer-quotations",
    name: t("Orders.QuotationList"),
    component: withSinglePlatformId(QuotationList, t("Orders.QuotationList")),
    permissions: [permission(STAFF_BFF, PENDING_SALES_ORDERS, READ)],
  },
  {
    exact: true,
    path: "/orders/sync",
    name: t("Orders.SyncUpData"),
    component: SyncUpData,
    permissions: [permission(STAFF_BFF, SYNC_UP_DATA)],
    flagKey: FLAG_KEYS.SYNC_UP_ORDER_DATA,
  },
  {
    exact: true,
    path: "/orders/import-shipments-status",
    name: t("Orders.ImportShipmentsStatus"),
    component: ImportShipmentsStatus,
    permissions: [permission(STAFF_BFF, OM_BATCH_UPDATE)],
    flagKey: FLAG_KEYS.BATCH_UPDATE_SHIPMENTS_STATUS,
  },
];
