import { FormInstance } from "antd/lib/form";
import { Dayjs } from "dayjs";
import {
  ICreatePurchaseInvoiceLine,
  IDeclarePurchaseInvoice,
  IDeclarePurchaseInvoiceGetParams,
  IPurchaseInvoiceCreatePayload,
  ISeller,
} from "models";
import { IPurchaseOrderReturnInvoice, IPurchaseOrderReturnInvoiceCreateForm } from "models/purchase-order-return";
import React, { createContext } from "react";

export interface IPurchaseInvoiceLineForm extends ICreatePurchaseInvoiceLine {
  price: number;
  quantityOnInvoice?: number;
}

export interface IPurchaseInvoiceCreateForm {
  poIds: string[];
  invoices: IPurchaseInvoice[];
}

export interface IPurchaseInvoice
  extends Omit<IPurchaseInvoiceCreatePayload, "dueDate" | "invoiceDate" | "invoiceLine"> {
  invoiceLine: IPurchaseInvoiceLineForm[];
  maxPaymentTerm: number;
  dueDate?: Dayjs;
  invoiceDate?: Dayjs;
  countIndex?: number;
}

interface IPurchaseInvoiceContext {
  purchaseInvoice: IDeclarePurchaseInvoice;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseInvoice: React.Dispatch<React.SetStateAction<IDeclarePurchaseInvoice>>;
  getDeclarePurchaseInvoiceData: (
    purchaseOrderIds: IDeclarePurchaseInvoiceGetParams["purchaseOrderIds"],
    sellerId: ISeller["id"]
  ) => void;
  form?: FormInstance<IPurchaseInvoiceCreateForm>;
}

export const PurchaseInvoiceContext = createContext<IPurchaseInvoiceContext>({
  purchaseInvoice: {},
  setLoading: () => null,
  setPurchaseInvoice: () => null,
  getDeclarePurchaseInvoiceData: () => null,
});

interface IPurchaseOrderReturnInvoiceContext {
  purchaseOrderReturnInvoice: IPurchaseOrderReturnInvoice;
  form: FormInstance<IPurchaseOrderReturnInvoiceCreateForm>;
  loadingGetPORInvoice: boolean;
  loadingCreatePORInvoice: boolean;
  canCreatePORInvoice?: boolean;
  getPurchaseOrderReturnInvoice: () => void;
}

export const PurchaseOrderReturnInvoiceContext = createContext<IPurchaseOrderReturnInvoiceContext>({
  purchaseOrderReturnInvoice: {},
  form: {} as FormInstance<IPurchaseOrderReturnInvoiceCreateForm>,
  loadingGetPORInvoice: false,
  loadingCreatePORInvoice: false,
  getPurchaseOrderReturnInvoice: () => null,
});
