import { ISeller } from "./common";
import { ISite, IWarehouse, WarehouseTypes } from "./warehouse";

export interface IStockQueryParam {
  keyword?: string;
  warehouses?: Array<string>;
  sites?: Array<string>;
  siteIds?: number[];
  page?: number;
  pageSize?: number;
  sellerId?: number;
}

export interface IStock {
  id: number;
  barcode: string;
  sku: string;
  sellerSku: string;
  uomName: string;
  category?: {
    code: string;
    name: string;
  };
  productId: number;
  productName: string;
  name?: string;
}

export interface IWarehouseStock extends IStock {
  warehouse: {
    id: number;
    code: string;
    name: string;
    available: number;
    onhand: number;
    reserved: number;
    outOfStock: boolean;
    updatedAt: string;
    updatedBy: string;
    warehouseType: WarehouseTypes;
  };
  rowSpan: number;
}

export interface IWarehouseStockRaw extends Omit<IStock, "rowSpan" | "warehouse"> {
  warehouses: Array<IWarehouseStock["warehouse"]>;
}

export interface IStockQueryParamWarehouse {
  id: number;
  code: string;
  name: string;
}

export interface IStockQueryParamSite {
  id: number;
  code: string;
  name: string;
  warehouses: Array<IStockQueryParamWarehouse>;
}

export interface IDownloadInventoryTemplateParams {
  page: number;
  pageSize: number;
  sellerId: number;
}

export interface IStockUpdateOnhandPayload {
  items: Array<{ warehouseCode: string; sku: string; currentOnhands: number; newOnhands: number }>;
}

export interface IAdjustmentHistory {
  id: number;
  currentOnhands: number;
  newOnhands: number;
  updatedAt: string;
  updatedBy: string;
}

export interface IAdjustmentHistoryQueryParams {
  warehouse?: IWarehouseStock["warehouse"]["code"];
  sku?: string;
}

export interface IFulfillmentChannel {
  id: number;
  name: string;
}

export interface IStockInfo {
  skus: {
    sku: string;
    sellerSku: string;
    warehouses: {
      code: string;
      available: number;
    }[];
  }[];
}

export interface IRequestStockPayload {
  siteId: ISite["id"];
  sellerId: ISeller["id"];
  lineItems: {
    sku: IStock["sku"];
    requestedQty: number;
  }[];
}

export interface GetHoldsBySkuQueryParams {
  sku?: IStock["sku"];
  warehouseId?: IWarehouse["code"];
}

export enum DocType {
  saleOrder = "DOC_TYPE_SO",
  stockTransfer = "DOC_TYPE_ST",
  returnToSupplier = "DOC_TYPE_POR",
  external = "DOC_TYPE_EXT",
  exportRequest = "EXPORT_REQUEST",
}

export interface IHoldsBySku {
  holdRequestId: number;
  holdQuantity: number;
  warehouseId: IWarehouse["code"];
  docType: DocType;
  docRef: string;
}

export interface IClearanceRequestQueryParams {
  sellerId?: number;
  bucketId?: number;
  originalSku?: string;
  page?: number;
  pageSize?: number;
  status?: string;
}

export interface IClearanceRequestDetailQueryParams {
  sellerId: number;
  bucketId: number;
  originalSku: string;
  page?: number;
  pageSize?: number;
  status?: string;
}

export interface IClearanceRequest {
  requestId: number;
  originalSku: string;
  displaySku: string;
  skuName: string;
  quantity: number;
  bucketId: number;
  bucketName: string;
  status: ClearanceRequestStatus;
  information: string[];
  serialOrLot: string;
  warehouseName: string;
  physicalQuantity: number;
  importedAt?: string;
  expiredAt?: string;
  note?: string;
  createdBy?: string;
}

export interface ClearanceRequestsResponse {
  clearanceRequests: IClearanceRequest[];
  totalItems: number;
  page?: number;
  pageSize?: number;
}

export enum ClearanceRequestStatus {
  available = "available",
  expired = "expired",
  approved = "approved",
}

export enum HoldingQuantity {
  notAvailable = -1,
}

export interface GetAvailableStockPayload {
  skus: string[];
  warehouseCodes: string[];
  sellerId: ISeller["id"];
}

export interface AvailableStockInfo {
  sku: string;
  items: {
    warehouseCode: string;
    physicalQuantity: number;
    availableQuantity: number;
  }[];
}

export interface IImbalancedStockGetListPayload {
  sku?: IStock["sku"];
  categoryId?: number;
  limit?: number;
  offset?: number;
}

export interface IImbalancedStock {
  sku: IStock["sku"];
  name: string;
  availableOnline: number;
  availableOffline: number;
}

export interface IInventoryBySkuParams {
  skus: string[];
  siteIds?: number[];
}

export interface IInventoryBySku {
  sku: string;
  siteId: number;
  salesQuantity: number;
  backorderQuantity: number;
  intransitQuantity: number;
}

export interface IInventoryBySkuResponse {
  items: IInventoryBySku[];
}

export interface IMapInventoryBySku extends IInventoryBySku {
  siteName: string;
}
