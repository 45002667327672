import requestApis from "apis/request";
import { commonConstants } from "constants/index";
import downloadjs from "downloadjs";
import {
  IBranch,
  IDeclarePurchaseInvoice,
  IDeclarePurchaseInvoiceGetParams,
  IExportInvoicesPayload,
  IExportInvoicesResponse,
  IPurchaseInvoiceCreatePayload,
  IPurchaseInvoiceCreateResponse,
  IPurchaseInvoicesCreatePayload,
  ISeller,
  IVatTypeData,
  StockTransferOutDownloadPayload,
  StockTransferOutGetPayload,
} from "models";
import {
  IPurchaseOrderReturnInvoice,
  IPurchaseOrderReturnInvoiceCreatePayload,
  IPurchaseOrderReturnInvoiceGetQueryParams,
} from "models/purchase-order-return";

const { asClient, wmsClient, staffBffClient } = requestApis;
const { X_SELLER_ID } = commonConstants;

const getStockTransferOut = (params: StockTransferOutGetPayload) => {
  return asClient.get("/api/report/stock_transfer_out", { params });
  // TODO: return data (array) & pagination ({ page, pageSize, total }) for all my GET LIST api
};

const downloadStockTransferOut = async (params: StockTransferOutDownloadPayload) => {
  const response = await asClient.get("/api/report/stock_transfer_out/excel", { params, responseType: "blob" });
  const contentDisposition = response.headers["content-disposition"] || "";
  const filenameIndex = contentDisposition.indexOf("filename=");
  let filename = filenameIndex >= 0 ? contentDisposition.split("filename=")[1] : undefined;
  if (filename && filename.startsWith('"') && filename.endsWith('"')) {
    filename = filename.substring(1, filename.length - 1);
  }
  return downloadjs(response.data, filename);
};

const getBranches = async (sellerId?: ISeller["id"]): Promise<IBranch[]> => {
  const response = await wmsClient.get("/api/branches/", { params: { sellerId } });
  return response.data.data;
};

const exportEInvoicesForTaka = async (payload: IExportInvoicesPayload): Promise<IExportInvoicesResponse> => {
  const response = await staffBffClient.post("/api/v1/orders/export/invoices", payload);
  return response.data;
};

const getDeclarePurchaseInvoice = async (
  params: IDeclarePurchaseInvoiceGetParams,
  sellerId?: ISeller["id"]
): Promise<IDeclarePurchaseInvoice> => {
  const response = await staffBffClient.get("/api/v1/purchase-invoice", {
    params,
    headers: { [X_SELLER_ID]: sellerId },
  });
  return response.data.data;
};

const createPurchaseInvoice = async (
  payload: IPurchaseInvoiceCreatePayload,
  sellerId: ISeller["id"]
): Promise<IPurchaseInvoiceCreateResponse> => {
  const response = await staffBffClient.post("/api/v1/purchase-invoice", payload, {
    headers: { [X_SELLER_ID]: sellerId },
  });
  return response.data;
};

const createPurchaseInvoices = async (
  payload: IPurchaseInvoicesCreatePayload,
  sellerId: ISeller["id"]
): Promise<IPurchaseInvoiceCreateResponse> => {
  const response = await staffBffClient.post("/api/v1/purchase-invoices", payload, {
    headers: { [X_SELLER_ID]: sellerId },
  });
  return response.data;
};

const getVatList = async (sellerId: ISeller["id"]): Promise<IVatTypeData[]> => {
  const response = await asClient.get("/api/vat_type", { headers: { [X_SELLER_ID]: sellerId } });
  return response.data.result;
};

const getPurchaseOrderReturnInvoice = async (
  params: IPurchaseOrderReturnInvoiceGetQueryParams
): Promise<IPurchaseOrderReturnInvoice> => {
  const response = await staffBffClient.get("/api/v1/purchase-return-invoice", {
    params,
  });
  return response.data.data;
};

const createPurchaseOrderReturnInvoice = async (payload: IPurchaseOrderReturnInvoiceCreatePayload) => {
  return await staffBffClient.post("/api/v1/purchase-return-invoice", payload);
};

export default {
  getStockTransferOut,
  downloadStockTransferOut,
  getBranches,
  exportEInvoicesForTaka,
  getDeclarePurchaseInvoice,
  createPurchaseInvoice,
  createPurchaseInvoices,
  getVatList,
  getPurchaseOrderReturnInvoice,
  createPurchaseOrderReturnInvoice,
};
